
import React, { Component } from 'react';

import '../App.css';


import Header from '../Site/SiteHeader.js';
import ArticlesTopNav from './ArticlesTopNav.js';
import ArticlesContentPanel from './ArticlesContentPanel.js';
import Footer from '../Site/SiteFooter.js';


var loggedOn = false;



class ArticlesPage extends Component {

  constructor(props) {

    super(props);

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      author: '',
      pubDate: '',
      revision: '',
      cat: '',
      rootCat: '',
      subCat: '',

      width: 0,
      height: 0

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;

    this.state.width = window.innerWidth;
    this.state.height = window.innerHeight;

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

componentDidMount() {
    document.title = "ECCEP"

  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);

}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });

}


  renderSiteHeader( ) {
    return <Header page={ this.state.page } />;
  }

  renderArticlesTopNav( ) {
    return <ArticlesTopNav userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }

  renderArticlesContentPanel( ) {
    return <ArticlesContentPanel userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }

  renderSiteFooter( ) {
    return <Footer />;
  }

  render() {

    if( loggedOn )
    {

      return (

        {/* this.renderSiteFooter( ) */}

      );
    }
    else
    {

      return (


        <div>

          { this.renderSiteHeader( ) }

          { this.renderArticlesTopNav( ) }

          { this.renderArticlesContentPanel( ) }

          { this.renderSiteFooter( ) }

        </div>



      );
    }

  }
}


export default ArticlesPage;

