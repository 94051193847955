
import React, { Component } from 'react';
import '../../App.css';



class AlamosaArticlesTopNavMenu extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    if( this.state.page === 'art-alamosa' ) {

      return(

        <div id="topNavMenuDiv">

          <table id="topNavMenuTable">
            <tbody>
              <tr>
                <td>
                  <a href="/Communities/">Communities</a>
                </td>
                <td>
                  <a href="/Projects/">Projects</a>
                </td>
                <td>
                  <a href="/Plans/">Plans</a>
                </td>
                <td>
                  <a href="/Articles/">Articles</a>
                </td>
                <td>
                  <a href="/Users/Login">Login</a>
                </td>
              </tr>

            </tbody>
          </table>

        </div>
      );
    }

    else {
      return(

        <div id="topNavMenuDiv">

          <table id="topNavMenuTable" >
            <tbody>
<tr>
                <td>
<a href="/Communities/">Communities</a>
                </td>
<td>
<a href="/Projects/">Projects</a>
</td>
<td>
<a href="/Plans/">Plans</a>
</td>
<td>
<a href="/Articles/">Articles</a>
</td>
</tr>
            </tbody>
          </table>

        </div>
      );
    }


  }
}


export default AlamosaArticlesTopNavMenu;
