
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';


import  SubscribeForm from '../../Site/Components/subscribeForm.js';


//import AlamosaArticlesCPane from './AlamosaArticlesCPane.js';
import ACDeniesPropertyAccess from './Alamosa-County-Denies-Property-Access-To-Disabled-Veteran.js';
import ACProtectsUnethicalAttorney from './Alamosa-County-Protects-Unethical-Attorney.js';
import ARHatePoorPeople from './Alamosa-Republicans-Hate-Poor-People.js';






const cpaneStyle = {
  margin: 0,
  position: 'relative',
  top: '50%',
  transform: 'translate( 0%, -5%)',
  msTransform: 'translate(0%, -5%)'

};

const subDivStyle = {
  margin: 0,
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate( -25%, 00%)',
  msTransform: 'translate(-25%, 00%)'

};




class AlamosaArticlesCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;


    //fetch articles & article information
    this.state.author = "J.Smith";
    this.state.pubDate = "7.4.19";

    this.state.cat = "";
    this.state.rootCat = "";
    this.state.subCat = "";

  }


  handleChange( ) {

  }

  renderSubscribeForm( ) {
    return <SubscribeForm />
  }

  renderACDeniesPropertyAccess( ) {
    return <ACDeniesPropertyAccess userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderACProtectsUnethicalAttorney( ) {
    return <ACProtectsUnethicalAttorney userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderARHatePoorPeople( ) {
    return <ARHatePoorPeople userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }

  render() {

    if( this.state.page === 'art-alamosa' ) {

      if( this.state.title === 'Alamosa County Denies Property Access To Disabled Veteran' ) {

        return (
          <div>
            { this.renderACDeniesPropertyAccess( ) }
          </div>
        );

      }//end if

      else if( this.state.title === 'Alamosa County Protects Unethical Attorney' ) {

        return (
          <div>
            { this.renderACProtectsUnethicalAttorney( ) }
          </div>
        );

      }//end else if

      else if( this.state.title === 'Alamosa Republicans Hate Poor People' ) {

        return (
          <div>
            { this.renderARHatePoorPeople( ) }
          </div>
        );

      }//end else if
  
      else {

        return (

          <div style={ cpaneStyle } >


             <Helmet>
               <title>ECCEP.co - Alamosa Articles</title>
               <meta name="keywords" content="ECCEP.co - Alamosa Articles" />
               <meta name="description" content="ECCEP.co Articles Alamosa Colorado San Luis Valley articles alamosa colorado san luis valley" />
             </Helmet>



            <p><br></br></p>

            <blockquote cite="">"If you think we can't change the world, it just means you're not one of those who will."</blockquote> Jacque Fresco

            <p><br></br></p>

            <p><b>Articles About Alamosa:</b></p>

            <p>Part of community building is informing the community of what occurs in the community.  While some stories are positive, others are critical, all of them are researched and rooted in facts.</p>

            <p><br></br></p>

            <p><b>Alamosa County:</b></p>

            <p><a href="/Articles/Alamosa-County/Alamosa-Republicans-Hate-Poor-People">Alamosa Republicans Hate Poor People</a></p>

            <p><a href="/Articles/Alamosa-County/Alamosa-County-Denies-Property-Access-To-Disabled-Veteran">Alamosa County Denies Property Access to a Disabled Veteran</a></p>

            <p><a href="/Articles/Alamosa-County/Alamosa-County-Protects-Unethical-Attorney">Alamosa County Protects Unethical Attorney</a></p>

            <p><br></br></p>

            <p><b>Alamosa County Employees:</b></p>

            <p><a href="http://dariusallen.org/Articles/">Darius Allen</a></p>

            <p><a href="http://helenSigmond.org/Articles/">Helen Sigmond</a></p>

            <p><a href="http://michaelyohn.org/Articles/">Michael Yohn</a></p>

            <p><a href="http://jasontkelly.org/Articles/">Jason T. Kelly</a></p>

            <p><br></br></p>

            { this.renderSubscribeForm( ) }

            <p><br></br></p>

          </div>
        );
      } // end else ( default )
    }//end if

  }//end render
}

export default AlamosaArticlesCPane;
