
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';



import '../../App.css';


import ArticleHeader from '../../Site/Components/ArticleHeader.js';
import ArticleFooter from '../../Site/Components/ArticleFooter.js';
import SubscribeForm from '../../Site/Components/subscribeForm.js';




class ARHatePoorPeople extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "J.Smith";
this.state.pubDate = "1.2.19";


  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderSubscribeForm( ) {
    return <SubscribeForm />
  }


  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
        <div>

           <Helmet>
             <title>{ this.state.title }</title>
             <meta name="keywords" content="Alamosa Republicans Hate Poor People" />
             <meta name="description" content="Alamosa Repuiblicans Hate Poor People" />
           </Helmet>



          <h2>{ this.state.title }</h2>

          <p><br></br></p>

          <p>On March 30, 2018, this post appeared on the Alamosa Republicans Facebook page.</p>

          <img src="/Images/Alamosa-Republicans-Hate-Poor-People.jpg" alt="" ></img>

          <p>It was later learned that these disgusting posts came from Mr. David E. Broyles of Alamosa, who was forced to resign his position with Alamosa Republicans and his position of Alamosa City Councilor.</p>  

          <img src="/Images/David-E-Broyles.png" alt="" ></img>

          <p>A quick glance on Michael Yohn's, Helen Sigmond and Jason T. Kelly's Facebook friends on January 18th, 2019 shows that they are still friends on Facebook with Mr. Broyles.  Furthermore, Michael Yohn openly campaigned with Alamosa Republicans in the 2018 election year knowing the values they represent.  Considering the information in this context, it is no wonder Alamosa doesn't have an even larger issue with bigotry.</p>

          <p>And just what exactly is a bigot?  According to Oxford, "bigot, noun, a person who has very strong, unreasonable beliefs or opinions about race, religion or politics and who will not listen to or accept the opinions of anyone who disagrees."  You can read the <a href="https://www.oxfordlearnersdictionaries.com/us/definition/english/bigot">Oxford definition of a bigot here</a>.</p>

          <p><br></br></p>

          <p>"The Hypocritical Commissioners"</p>

          <p>When one considers that a majority of Alamosa County is affiliated with Alamosa Republicans after their hatefulness was publicly displayed, you start to question just why there are so many poor people in Alamosa.  Might it be a direct result of their corruption, nepotism and unequal application of the law?  This has surely been our experiences with Alamosa County.</p>

          <p>It occurs to me that it is beyond hypocritical to hate 'poor' people, especially when you are the one who is holding them back and preventing them from doing better in life.  It is infinitely worse that the Alamosa County Commissioners are so blind and ignorant to the ill effects they have on the community.  It is their failures that are preventing the community from growing and being prosperous for all.  Not just those they decide are worthy.</p>

          <p><a href="http://dariusallen.org">Darius Allen</a>, <a href="http://michaelyohn.org">Michael Yohn</a> and <a href="http://helensigmond.org">Helen Sigmond</a> are all three directly responsible for costing the county and San Luis Valley countless economic potential and revenue all because they believe they can legally deny a property owner the right to even access their own property, let alone farm it in accordance with County and State ordinances explicitly giving property owners the right to do just that.</p>

          <p><br></br></p>

          { this.renderArticleFooter( ) }

          <p><br></br></p>

          { this.renderSubscribeForm( ) }

          <p><br></br></p>

        </div>
      );
    }
  }
}


export default ARHatePoorPeople;
