
import React, { Component } from 'react';
import '../../App.css';


class LoginForm extends Component {


  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      username: '',
      password: '',
      usrnMsg: '',
      pswdMsg: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    this.handleUsernameChange = this.handleUsernameChange.bind( this );
    this.handlePasswordChange = this.handlePasswordChange.bind( this );

    this.handleSubmit = this.handleSubmit.bind( this );

  }


  handleNChange( event ) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState( { [name]: value } );
  }

  //handleUsernameChange( event ) {
  //  this.setState( { [event.target.name]: event.target.value } );
  //}

  handleUsernameChange( event ) {
    this.setState( { username: event.target.value } );
  }
  handlePasswordChange( event ) {
    this.setState( { password: event.target.value } );
  }

  handleSubmit( event ) {

    event.preventDefault();

    var isUserNull;
    var isEmailAddr = this.state.username.includes( '@' );

    var isPswdNull;
    var isPswdValid;

    if( this.state.username.length === 0  ) {
      isUserNull = true;
    }

    if( this.state.password.length === 0 ) {
      isPswdNull = true;
    }

    if( this.state.password.length <= 7 ) {
      isPswdValid = false;
    }


    if( isUserNull ) {
      this.setState( { usrnMsg: "Username can not be null" } );
    }
    else {

      if( isEmailAddr ) {
        this.setState( { usrnMsg: "Username can not be an email address" } );
      }
      else {
        this.setState( { usrnMsg: "" } );
      }
    }

    if( isPswdNull ) {
      this.setState( { pswdMsg: "Password can not be null" } );
    }
    else {

      if( !isPswdValid ) {
        this.setState( { pswdMsg: "Password must be at least 8 characters long" } );
      }
      else {
        this.setState( { pswdMsg: "" } );
      }
    }


    if( isUserNull | isEmailAddr | isPswdNull ) {
      //do nothing, just display error messages and allow user to correct then resubmit
      console.log( "Form field errors" );
    }
    else {

      var rqst = 'https://eccep.co/backend/Users/login';

        fetch( rqst, {

          method: 'POST',
          mode: 'cors',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify( {
            username: this.state.username,
            password: this.state.password
          })

        })
        .then( resp => resp.json( ) )
        .then( function( resp ) {
        console.log( "resp: ", resp );
        })
      .then( function( resp ) {

      })
      .catch( function( err ) {
        console.log( 'Error: ' + err );
      });
    } // end else ( if( isEmailAddr ) )

  }

  render( ) {

    return(

      <div>

        <form id="loginForm" name="loginForm" onSubmit={ this.handleSubmit }>

          <table id="loginTable">
            <tbody>
              <tr>
                <td>
                  { this.state.usrnMsg }
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Username:
                    <input id="username" name="username" type="text" value={ this.state.username } onChange={ this.handleUsernameChange } />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  { this.state.pswdMsg }
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Password:
                    <input id="password" name="password" type="password" value={ this.state.password } onChange={ this.handlePasswordChange } />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="submit" value="Submit" />
                </td>
              </tr>
              <tr>
                <td>
                  <a href="/Users/Registration">Register for an account here</a>
                </td>
              </tr>

            </tbody>
          </table>
        </form>

      </div>
    );
  }
}

export default LoginForm;