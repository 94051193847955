
import React, { Component } from 'react';

import '../App.css';


import RegistrationForm from './Forms/RegistrationCPane.js';



var loggedOn = false;




class RegistrationCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }

  renderRegistrationForm( ) {
    return <RegistrationForm page={ this.state.page } />;
  }

  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

<p>Registration CPane</p>

{ this.renderRegistrationForm( ) }

         </div>

      );
    }
  }
}


export default RegistrationCPane;
