
import React, { Component } from 'react';

import '../App.css';

import LoginForm from './Components/loginForm.js';


var loggedOn = false;


class LoginCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }

  renderLoginForm( ) {
    return <LoginForm page={ this.state.page } />;
  }

  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

<p>Login CPane</p>

{ this.renderLoginForm( ) }

         </div>

      );
    }
  }
}


export default LoginCPane;
