import React, { Component } from 'react';
import '../../App.css';



class RegistrationCPane extends Component {


  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      username: '',
      password: '',
      emailAddress: '',
      firstName: '',
      middleName: '',
      lastName: '',
      usrnMsg: '',
      pswdMsg: '',
      emailMsg: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    this.handleUsernameChange = this.handleUsernameChange.bind( this );
    this.handlePasswordChange = this.handlePasswordChange.bind( this );
    this.handleEmailChange = this.handleEmailChange.bind( this );
    this.handleFirstNameChange = this.handleFirstNameChange.bind( this );
    this.handleMiddleNameChange = this.handleMiddleNameChange.bind( this );
    this.handleLastNameChange = this.handleLastNameChange.bind( this );

    this.handleSubmit = this.handleSubmit.bind( this );

  }


  handleNChange( event ) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState( { [name]: value } );
  }

  handleFirstNameChange( event ) {
    this.setState( { firstName: event.target.value } );
  }

  handleMiddleNameChange( event ) {
    this.setState( { middleName: event.target.value } );
  }

  handleLastNameChange( event ) {
    this.setState( { lastName: event.target.value } );
  }

  handleEmailChange( event ) {

    this.setState( { emailAddress: event.target.value } );
  }

  handleUsernameChange( event ) {
    this.setState( { username: event.target.value } );
  }

  handlePasswordChange( event ) {
    this.setState( { password: event.target.value } );
  }

  isEmailUnique( onSuccess,  onFail ) {

    return new Promise( ( resolve, reject ) => {

      var rqst = 'https://eccep.co/backend/Users/isEmailUnique';

      fetch( rqst, {

        method: 'POST',
        mode: 'cors',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify( {
          emailAddress: this.state.emailAddress
        })

      })
      .then( resp => resp.json( ) )
      .then( function( resp ) {
        console.log( "resp0: ", resp );
        resolve( resp );
      })
      .catch( function( err ) {
        console.log( 'Error: ' + err );
        reject( err );
      });

    } )
  }

  register( onSuccess,  onFail ) {

    return new Promise( ( resolve, reject ) => {

      var rqst = 'https://eccep.co/backend/Users/register';

      fetch( rqst, {

        method: 'POST',
        mode: 'cors',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify( {
          emailAddress: this.state.emailAddress,
          username: this.state.username,
          password: this.state.password,
          firstName: this.state.firstName,
          middleName: this.state.middleName,
          lastName: this.state.lastName
        })

      })
      .then( resp => resp.json( ) )
      .then( function( resp ) {
        console.log( "resp1: ", resp );
        resolve( resp );
      })
      .catch( function( err ) {
        console.log( 'Error: ' + err );
        reject( err );
      });

    } )
  }


  handleSubmit( event ) {

    event.preventDefault();

    var isUserNull;
    var isEmailAddr = this.state.username.includes( '@' );

    var isPswdNull;
    var pswdHasUCChar;
    var pswdHasLCChar;
    var pswdHasDigit;
    var pswdHasSChar;

    var isPswdValid;
    var isPswdSecure;


    this.setState( { usrnMsg: ""} );
    this.setState( { pswdMsg: ""} );

    if( this.state.username.length === 0  ) {
      isUserNull = true;
    }

    if( this.state.password.length === 0 ) {
      isPswdNull = true;
    }

    if( this.state.password.length <= 7 ) {
      isPswdValid = false;
    }

    if( isUserNull ) {
      this.setState( { usrnMsg: "Username can not be null" } );
    }
    else {

      if( isEmailAddr ) {
        this.setState( { usrnMsg: "Username can not be an email address" } );
      }
      else {
        this.setState( { usrnMsg: "" } );
      }
    }

    if( isPswdNull ) {
      this.setState( { pswdMsg: "Password can not be null" } );
    }
    else {

      var pattern = /^[a-z]+$/
      pswdHasLCChar = this.state.password.match( pattern );

      pattern = /^[A-Z]+$/
      pswdHasUCChar = this.state.password.match( pattern );

      pattern = /^[0-9]+$/
      pswdHasDigit = this.state.password.match( pattern );

      pattern = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/
      pswdHasSChar = this.state.password.match( pattern );

      isPswdSecure = ( pswdHasUCChar && pswdHasLCChar && pswdHasDigit && pswdHasSChar );


      if( !isPswdSecure ) {

        this.setState( { pswdMsg: "" } );

        if( !pswdHasLCChar ) {
          this.setState( { pswdMsg: "Password must contain at least one lower case char." } );
        }

        if( !pswdHasUCChar ) {
          this.setState( { pswdMsg: "Password must contain at least one upper case character" } );
        }
        if( !pswdHasDigit ) {
          this.setState( { pswdMsg: "Password must contain at least one digit" } );
        }
        if( !pswdHasSChar ) {
          this.setState( { pswdMsg: "Password must contain at least one special character" } );
        }

      }
      else {
        this.setState( { pswdMsg: "" } );
      }
    }


    if( isUserNull | isEmailAddr | isPswdNull ) {
      //do nothing, just display error messages and allow user to correct then resubmit
      console.log( "Form field errors" );
    }
    else {
      this.setState( { usrnMsg: "" } );
      this.setState( { pswdMsg: "" } );

      this.isEmailUnique( )
        .then( resp => {
          console.log( "resp2: ", resp );

          if( resp > 0 ) {
            console.log( "Record already exists!" );
          }
          else {
            this.register( );
          }
        } )
        //.then( function( resp ) {
        //  console.log( "emailAddress: ", eadr );
          //numRecs = [resp.length]
        //} )
        .catch( function( err ) {
          console.log( 'Error: ' + err );
        });
      }

   }



  render( ) {

    return(

      <div >

        <form className="subscribeForm" id="subscribeForm" name="subscribeForm" onSubmit={ this.handleSubmit } >

          <table id="subscribeTable" name="subscribeTable" >
            <tbody>

              <tr>
                <td colSpan="4">
                  First Name
                </td>
                <td colSpan="1" >
                  Middle
                </td>
                <td colSpan="4">
                  Last Name
                </td>
              </tr>
              <tr>
                <td colSpan="4" >
                  <input
                    id="firstName"
                    name = "firstName"
                    type = "text"
                    size = "12"
                    value = { this.state.firstName }
                    onChange = { this.handleFirstNameChange } /> 
                </td>
                <td colSpan="1" >
                  <input
                    id="middleName"
                    name = "middleName"
                    type = "text"
                    size = "1"
                    value = { this.state.middleName }
                    onChange = { this.handleMiddleNameChange } /> 
                </td>
                <td colSpan="4" >
                  <input
                    id="lastName"
                    name = "lastName"
                    type = "text"
                    size = "12"
                    value = { this.state.lastName }
                    onChange = { this.handleLastNameChange } /> 
                </td>
              </tr>
              <tr>
                <td colSpan="9">
                  { this.state.emailMsg }
                </td>
              </tr>
              <tr>
                <td colSpan="9" >
                    Email Address
                </td>
              </tr>
              <tr>
                <td colSpan="9" >
                    <input
                      id="emailAddress"
                      name="emailAddress"
                      type="text"
                      size = "48"
                      value={ this.state.emailAddress }
                      onChange={ this.handleEmailChange } />
                </td>
              </tr>
              <tr>
                <td colSpan="9">
                  { this.state.usrnMsg }
                </td>
              </tr>
              <tr>
                <td colSpan="9">
                  { this.state.pswdMsg }
                </td>
              </tr>
              <tr>
                <td colSpan="4" >
                  Username
                </td>
                <td colSpan="4" >
                  Password
                </td>
              </tr>
              <tr>
                <td colSpan="4" >
                    <input
                      id="username"
                      name="username"
                      type="text"
                      size = "16"
                      value={ this.state.username }
                      onChange={ this.handleUsernameChange } />
                </td>
                  <td colSpan="9" >
                    <input
                      id="password"
                      name="password"
                      type="password"
                      size = "16"
                      value={ this.state.password }
                      onChange={ this.handlePasswordChange } />
                </td>
              </tr>
              <tr>
                <td colSpan="9" >
                  <input type="submit" value="Register" onClick={ this.handleSubmit } />
                </td>
              </tr>


            </tbody>
          </table>
        </form>

      </div>
    );
  }
}

export default RegistrationCPane;