
import React, { Component } from 'react';
import '../App.css';



class TopNavMenu extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    if( this.state.page === 'Site'  ) {

      if( this.state.title === 'ECCEP' ) {

        return(
          <div style={{ textAlign: 'center' }} >
            <table id="topNavMenuTable">
              <tbody>
                <tr >
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      else if( this.state.title === 'TermsOfService' ) {

        return(
          <div id="topNavMenuDiv">
            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      else if( this.state.title === 'PrivacyPolicy' ) {

        return(
          <div id="topNavMenuDiv">
            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      else if( this.state.title === 'Sitemap' ) {

        return(
          <div id="topNavMenuDiv">
            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      else if( this.state.title === 'AboutUs' )
      {

        return(
          <div id="topNavMenuDiv">
            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      else if( this.state.title === 'ContactUs' ) {

        return(
          <div id="topNavMenuDiv">
            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
      else {

        return(
          <div id="topNavMenuDiv">
            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } // end else ( default )
    }// end if( this.state.page === 'Site' )

    else {

      if( this.state.page === 'registration' )
      {
        return(

          <div id="topNavMenuDiv">

            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>
        );
      }

      else if( this.state.page === 'login' )
      {
        return(

          <div id="topNavMenuDiv">

            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>
        );
      }

      else if( this.state.page === 'logout' )
      {
        return(

          <div id="topNavMenuDiv">

            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>
        );
      }


    else if( this.state.page === 'communities' )
    {
      return(

        <div id="topNavMenuDiv">

          <table id="topNavMenuTable">
            <tbody>
              <tr>
                <td>
			<a href="/Communities/">Communities</a>
                </td>
		<td>
			<a href="/Projects/">Projects</a>
		</td>
		<td>
			<a href="/Plans/">Plans</a>
		</td>
		<td>
			<a href="/Articles/">Articles</a>
		</td>
		<td>
			<a href="/Users/Login">Login</a>
		</td>
              </tr>

            </tbody>
          </table>

        </div>
      );
    }

    else if( this.state.page === 'projects' )
    {
      return(

        <div id="topNavMenuDiv">

          <table id="topNavMenuTable">
            <tbody>
              <tr>
                <td>
<a href="/Communities/">Communities</a>
                </td>
<td>
<a href="/Projects/">Projects</a>
</td>
<td>
<a href="/Plans/">Plans</a>
</td>
<td>
<a href="/Articles/">Articles</a>
</td>
<td>
<a href="/Users/Login">Login</a>
</td>
              </tr>

            </tbody>
          </table>

        </div>
      );
    }

    else if( this.state.page === 'proj-alamosa' )
    {
      return(

        <div id="topNavMenuDiv">

          <table id="topNavMenuTable">
            <tbody>
              <tr>
                <td>
<a href="/Communities/">Communities</a>
                </td>
<td>
<a href="/Projects/">Projects</a>
</td>
<td>
<a href="/Plans/">Plans</a>
</td>
<td>
<a href="/Articles/">Articles</a>
</td>
<td>
<a href="/Users/Login">Login</a>
</td>
              </tr>

            </tbody>
          </table>

        </div>
      );
    }
      else if( this.state.page === 'plans' )
      {
        return(

          <div id="topNavMenuDiv">

            <table id="topNavMenuTable">
              <tbody>
                <tr>
                  <td>
                    <a href="/Communities/">Communities</a>
                  </td>
                  <td>
                    <a href="/Projects/">Projects</a>
                  </td>
                  <td>
                    <a href="/Plans/">Plans</a>
                  </td>
                  <td>
                    <a href="/Articles/">Articles</a>
                  </td>
                  <td>
                    <a href="/Users/Login">Login</a>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>
        );
      }

    else {
      return(

        <div id="topNavMenuDiv">

          <table id="topNavMenuTable" >
            <tbody>
<tr>
                <td>
<a href="/Communities/">Communities</a>
                </td>
<td>
<a href="/Projects/">Projects</a>
</td>
<td>
<a href="/Plans/">Plans</a>
</td>
<td>
<a href="/Articles/">Articles</a>
</td>
</tr>
            </tbody>
          </table>

        </div>
      );
    }



    }


  }
}


export default TopNavMenu;
