
import React, { Component } from 'react';

import '../App.css';

var loggedOn = false;


const cpaneStyle = {
  margin: 0,
  position: 'relative',
  top: '50%',
  transform: 'translate( 0%, -50%)',
  msTransform: 'translate(0%, -50%)'

};




class SitesCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div >

           <p>
             <br></br><b>About ECCEP.co</b>
           </p>

           <p>
             <br></br><b>Why we were founded?</b>
           </p>

           <p>
             ECCEP is a project that has been planned and thought out since 2012.  Despite this, it was not until witnessing firsthand the injustices in Southern Colorado, Alamosa County in particular, that compelled us to set things in motion.  Community building is a large task that consumes a lot of resources.  Despite this, we believe that change can be most effectively applied on this level, when you empower individuals.  In an effort to avoid the duplication of mistakes and costly errors, we decided to bring these two aspects together to improve society.
           </p>

           <p><br></br></p>

           <p>
             <br></br><b>Our Mission</b>
           </p>
           <p>
             It is our goal to build sustainable communities that encourage individual achievement, personal responsibility as well as community and economic prospeity.
           </p>
           <p>
             By adopting a modified business incubator framework, we believe that private communities can provide for their own needs in a more effective, efficient and responsive manner than government.  Particularly in rural communities with a history of poverty, local government lacks the experience, insight or knowledge of solutions to solve the problem.  We do not acccept that more of the same failed policies will work any better in the future.  
           </p>

           <p><br></br></p>

           <p>
             <br></br><b>Who are we?</b>
           </p>
           <p>
             ECCEP was founded by a group of disabled veterans trying to make the world a better place for themselves.  As anyone knows, the only way to lasting gains made in the realms of progress are those that improve the quality of life not only for themselves, but that of others as well will achieve peace and prosperity.
           </p>

           <p><br></br></p>

           <blockquote cite="">"Tolerance, diversity, and inclusion are <span style={{ background: "yellow" }} >not political opinions. They are non-negotiable human rights</span> - hard fought and secured in America."</blockquote>
           Andy Dunn

           <blockquote cite="">"<span style={{ background: "yellow" }} >While poverty persists, there is no true freedom</span>."</blockquote>
           Nelson Mandela, Speech for the "Make Poverty History" campaign. Trafalgar Square, London (3 February 2005).

           <blockquote cite="">"Only free men can negotiate; prisoners cannot enter into contracts. <span style={{ background: "yellow" }} >Your freedom and mine cannot be separated</span>."</blockquote>
           Nelson Mandela, refusing to bargain for freedom after 21 years in prison, as quoted in TIME (25 February 1985)

           <blockquote cite="">"<span style={{ background: "yellow" }} >Inclusion, gentlemen, not exclusion, is the key to survival</span>.  Something our new government should take heed of, eh?</blockquote>
           St. John, The Power of One

           <blockquote cite="">I've been a Republican all my life because I embrace the conservative values of hard work, personal freedom, less government and fewer taxes. <span style={{ background: "yellow" }} >But I also believe in compassion, inclusion, and helping those who want to help themselves.</span>"</blockquote>
           Lionel Sosa

           <p><br></br></p>

           <p>
             <br></br><b>Contact ECCEP.co</b>
             <br></br>Address: PO Box 632
             <br></br>Alamosa, CO 81101
             <br></br>Phone: (719) 000-0000
             <br></br>Email: Click for Email
           </p>


         </div>

      );
    }
  }
}


export default SitesCPane;
