
import React, { Component } from 'react';
import '../App.css';

import Wide_Sky from '../Ads/Wide_Skyscraper.png';


class RightPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    return(

      <div id="rightPaneDiv">

          <table id="rightPaneTable">
            <tbody>
              <tr>
                <td>

                    {/* <img src={Wide_Sky} /> */}

                </td>
              </tr>
            </tbody>
          </table>

      </div>
    );
  }
}


export default RightPane;
