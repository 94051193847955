

import React, { Component } from 'react';

import '../App.css';



var loggedOn = false;




class CommunitiesCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {
      return (

         <div>

           <p><b>Communities CPane</b></p>

           <p><a href="/Communities/EsperantoSLV/">EsperantoSLV</a></p>

         </div>

      );
    }
  }
}


export default CommunitiesCPane;
