

import React, { Component } from 'react';

import '../../App.css';


import AlamosaArticlesTopNavMenu from './AlamosaArticlesTopNavMenu.js';
import AlamosaArticlesTopNavSubMenu from './AlamosaArticlesTopNavSubMenu.js';



class AlamosaArticlesTopNav extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      curTime: '',
      temp: 0
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    this.state.curTime = new Date();

  }

componentDidMount( ) {
    this.intervalID = setInterval( () => {
      this.tick( );
    }, 1000 );
  }

  componentWillUnmount() {
    clearInterval( this.intervalID );
  }

  tick() {
    this.setState({ curTime: new Date() });
  }

  getTime( ) {
    var ct = new Date( this.state.curTime );

    var hours = ct.getHours( );
    if( hours < 10 ) {
      hours = '0' + hours;
    }

    var mins = ct.getMinutes( );
    if( mins < 10 ) {
      mins = '0' + mins;
    }

    var secs = ct.getSeconds( );
    if( secs < 10 ) {
      secs = '0' + secs;
    }

    var str = hours + ":" + mins + ":" + secs;

    return str;
  }

  getTemp( ) {
    return 0.0 + ' F';
  }

  renderAlamosaArticlesTopNavMenu( ) {
    return <AlamosaArticlesTopNavMenu page={ this.state.page } />;
  }

  renderAlamosaArticlesTopNavSubMenu( ) {
    return <AlamosaArticlesTopNavSubMenu page={ this.state.page } />;
  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    return(

      <div id="topNavDiv">

          <table id="topNavTable">
            <tbody>
              <tr>
                <td id="topNavULTD">

                </td>
                <td id="topNavCTD" >

                  { this.renderAlamosaArticlesTopNavMenu( ) }

                </td>
                <td id="topNavURTD">
{ this.getTime( ) }
                </td>
              </tr>
              <tr>
                <td id="topNavLLTD">

                </td>
                <td id="topNavCTD">

                  { this.renderAlamosaArticlesTopNavSubMenu( ) }

                </td>
                <td id="topNavLRTD">
{ this.getTemp( ) }
                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default AlamosaArticlesTopNav;