
import React, { Component } from 'react';

import '../App.css';


import Header from './SiteHeader.js';
import TopNav from './TopNav.js';
import ContentPanel from './ContentPanel.js';
import Footer from './SiteFooter.js';


var loggedOn = false;


class LoginPage extends Component {

constructor(props) {

  super(props);

  this.state = {

    value: null,
    userID: '',
    page: '',
    title: '',
    width: 0,
    height: 0

  };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;


  this.state.width = window.innerWidth;
  this.state.height = window.innerHeight;

  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

}

componentDidMount() {
    document.title = "ECCEP"

  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);

}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });

}


  renderSiteHeader( ) {
    return <Header page = { this.state.page } />;
  }

  renderTopNav( ) {
    return <TopNav page= { this.state.page }/>;
  }

  renderContentPanel( ) {
    return <ContentPanel page= { this.state.page } />;
  }

  renderSiteFooter( ) {
    return <Footer />;
  }

  render() {

    if( loggedOn )
    {

      return (

        {/* this.renderSiteFooter( ) */}

      );
    }
    else
    {

      return (


        <div>

          { this.renderSiteHeader( this.state.page ) }

          { this.renderTopNav( this.state.page ) }

          { this.renderContentPanel( this.state.page ) }

          { this.renderSiteFooter( ) }

        </div>



      );
    }

  }
}


export default LoginPage;