

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../App.css';


import ArticleHeader from '../Site/Components/ArticleHeader.js';
import ArticleFooter from '../Site/Components/ArticleFooter.js';

import  SubscribeForm from '../Site/Components/subscribeForm.js';


class RefusalOfServiceAnimals extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "J.Smith";
this.state.pubDate = "21.2.2019";


  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderSubscribeForm( ) {
    return <SubscribeForm />
  }


  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

           <Helmet>
             <title>{ this.state.title }</title>
             <meta name="keywords" content="Southern Colorado Refuses Service Animals Discriminates Disabled Veterans southern colorado refuses service animals discriminates against disabled veteran" />
             <meta name="description" content="Southern Colorado Refuses Service Animals" />
           </Helmet>



           <p><br></br></p>

           <h2><u>{ this.state.title }</u></h2>

           <p><br></br></p>

           <blockquote cite="">
             "According to the U.S. Census Bureau's American Community Survey, approximately 10.3 percent of Colorado's population reported having a disability in 2015.  Many of those individuals use a service animal or assistance animal to cope with their disability.  This issue brief defines the terms "service animal" and "assistance animal," and discusses the federal and state laws that address them."
           </blockquote>
           <a href="https://leg.colorado.gov/sites/default/files/issue_brief_-_service_animals_and_assistance_animals_5122017.pdf">leg.colorado.gov's Issue Brief on Service and Assistance Animals</a>

           <p><br></br></p>

           <p>
             The San Luis Valley has a disabled population of 15.6% according to 2017 from <a href="https://www.census.gov/quickfacts/fact/table/alamosacountycolorado/AFN120212">www.census.gov</a>.  Despite this, there is little knowledge or awareness of Service Animals and the law concerning them.
           </p>

           <p>
             <b>It is against Federal and State law to refuse service to anyone</b> based on race, color, religion, sex, national origin or <b>disability</b>.  Despite this, and Colorado's claim to being a progressive and pet friendly state overall, refusal of service to the disabled population occurs all too frequently.
           </p>


           <p><br></br></p>


           <p>
             <br></br><b>What is a Service Animal?</b>
           </p>

           <p>
             The ADA has defined a service animal as "a dog that is individually trained to do work or perform tasks for a person with a disability."
           </p>

           <blockquote cite="https://www.ada.gov/service_animals_2010.htm">"
             <span style={{ background: "yellow" }} >Service animals are defined as dogs that are individually trained to do work or perform tasks for people with disabilities</span>. Examples of such work or tasks include guiding people who are blind, alerting people who are deaf, pulling a wheelchair, alerting and protecting a person who is having a seizure, reminding a person with mental illness to take prescribed medications, calming a person with Post Traumatic Stress Disorder (PTSD) during an anxiety attack, or performing other duties. <span style={{ background: "yellow" }} ><b>Service animals are working animals, not pets</b></span>. The work or task a dog has been trained to provide must be directly related to the person's disability.
           </blockquote>
           <a href="https://www.ada.gov/service_animals_2010.htm">Service Animals 2010 on ada.gov</a>

           <p>
             
           </p>


           <p><br></br></p>


           <p>
             <br></br><b>Differences between Emotional Support Animals and Service Animals</b>
           </p>

           <p>
             The main difference can be found in the definitions.
           </p>

           <blockquote cite="">"ADA regulations define a "service animal" as any dog that is <b>individually trained to do work or perform
             tasks for the benefit of an individual with a disability</b>, including a physical, sensory, psychiatric, intellectual, or other 
             mental disability.2 A dog that provides its owner emotional support or comfort does not meet the definition of a service animal."
           </blockquote>

           <p>
             Service Animals are individually trained to do work or perform tasks for a person with a disability.  Service Animals and Handlers are known as teams because they have trained to work together, generally to perform a basic function independent of outside assistance.  By enabling the disabled population to care or provide for themselves independently is arguably the heart of the Americans with Disability Act of 1990.
           </p>

           <p>
             For a Service Animal to meet the definition, their handler must have a disability as defined by the ADA;
           </p>

           <blockquote cite="">
             "An individual with a disability is defined by the ADA as a person who has a physical or mental impairment that substantially limits one or more major life activities, a person who has a history or record of such an impairment, or a person who is perceived by others as having such an impairment."
           </blockquote>

           <p><br></br></p>

           <p>
             Emotional Support Animals are not specially trained to provide a service, but they are protected under the FHA and ACAA to live and fly with their owner.
           </p>

           <p>
             Here is a good article to read more about <a href="https://esadoctors.com/colorado-emotional-support-animal/">Emotional Support Animals</a> on http://esadoctors.com
           </p>



           <p><br></br></p>


           <p>
             <br></br><b>Laws protecting disabled / service animals</b>
           </p>

           <p>
             In 1990, the Department of Justice implemented the Americans with Disabilities Act which prohibits discrimination based on disability.  The State of Colorado also passed legislation granting protections against discrimination due to disability.
           </p>

           <p><br></br></p>

           <p>
             <br></br><b>Federal Anti-Discrimination Laws</b>
           </p>

           <p>
             <br></br><b>What is the ADA?</b>
           </p>

           <p>
             The Americans with Disabilities Act ( ADA ) is a civil rights law which was passed in 1990 and updated in 2010, that prohibits discrimination
             against individuals with disabilities in all areas of public life, including jobs, schools, transportation, and all public and private
             places that are open to the general public.  The ADA is administered by the Disability Rights Section, Civil Rights Division of the US Justice Department.
           </p>

           <p><br></br></p>

           <blockquote cite="">
             "The Americans with Disabilities Act (ADA) was signed into law on July 26, 1990, by President George H.W. Bush. The ADA is one of America's most comprehensive pieces of civil rights legislation that <b>prohibits discrimination and guarantees that people with disabilities have the same opportunities as everyone else</b> to participate in the mainstream of American life -- to enjoy employment opportunities, to purchase goods and services, and to participate in State and local government programs and services. Modeled after the Civil Rights Act of 1964, which prohibits discrimination on the basis of race, color, religion, sex, or national origin - and Section 504 of the Rehabilitation Act of 1973 -- the ADA is an <b>"equal opportunity" law for people with disabilities</b>."
           </blockquote>
           You can read more on the ADA's website here <a href="https://www.ada.gov/ada_intro.htm">Americans with Disabilities Act Intro</a>


           <p>
             <br></br>
           </p>

           <p>
             <br></br><b>Colorado Anti-Discrimination Laws</b>
           </p>

           <p>
             The State of Colorado has passed legislation that is largely similar to the ADA granting protections not only to Service Animal teams but also Service Animals in training.  Additionally, Colorado's Criminal Code prescribe a criminal penalty for anyone who intefers with a disabled person or their rights; Class 3 Misdemeanor.
           </p>

           <p><br></br></p>

           <blockquote cite="">
             "Service animals <span style={{ background: "yellow" }} ><b>are not required to be professionally trained or to wear a vest, patch, ID tag,
             or special harness</b></span>.  Further, the presence of a vest, patch, ID tag, or harness does not confer any special privileges or 
             protections to the owner of an animal that does not meet the definition of a service animal.

             If a person's disability or the work performed by his or her service animal is not readily
             apparent, a covered entity may ask the following questions in order to verify that the
             animal is a service animal: <span style={{ background: "yellow" }} >1) Is this a service animal that is required because of a disability?
             and 2) What work or tasks has the animal been trained to perform? A covered entity <b>cannot
             require the person to provide documentation that the service animal is trained</b></span>, and can only
             deny access to the service animal if: it is out of control and the handler does not take effective
             steps to control it; it is not housebroken; or it poses a threat to the health and safety of others
             that cannot be reduced to an acceptable level by making other reasonable modifications.
           </blockquote>

           <blockquote cite="">
           "The Americans with Disabilities Act of 1990 (ADA) is a civil rights law that <span style={{ background: "yellow" }} >prohibits discrimination
           based on a person's disability. The ADA <b>requires</b> covered entities, including state and
           local governments, businesses, and nonprofit organizations that provide goods or services to
           the public, to make reasonable accommodations for persons with disabilities.
           <b> The use of a service animal is considered a reasonable accommodation</b></span>."
           </blockquote>
           <a href="https://leg.colorado.gov/sites/default/files/issue_brief_-_service_animals_and_assistance_animals_5122017.pdf">leg.colorado.gov's Issue Brief on Service and Assistance Animals</a>

           <p><br></br></p>

           <p>
             Colorado Criminal Code states;
           </p>
           <p>
             (3) <b>A person shall not knowingly deny an individual with a disability</b>, as defined in section 24-34-301 (5.6), C.R.S., any right or privilege protected in section 24-34-502, 24-34-502.2, 24-34-601, 24-34-802 (1), or 24-34-803, C.R.S.
             (4) Violation of the provisions of subsection (1) of this section is a class 1 petty offense. <b>Violation of the provisions of subsection (3) of this section is a class 3 misdemeanor</b>.
           </p>

           <p>
             Class 3 misdemeanors are the least serious misdemeanor under Colorado's laws, punishable by up to six months in jail, a fine of $50 to $750, or both. 
           </p>

           <p>
             The importance of including of the disabled population was further acknowledged by Colorado on the 25th anniversary of the ADA.  Colorado passed Senate Joint Resolution 15-012 in 2015 which emphasized equality for the disabled population by prohibiting discrimination against people with disabilities in all area of public life and expanding opportunities by reducing barriers, changing perceptions and increasing full participation in community life.  You can read <a href="http://coloradodisabilitycouncil.org/wp-content/uploads/2015/08/SenateJointResolution15-012.pdf">Resolution 15-012 here.</a>
           </p>

           <p>
             Or do they?  In 2017, Colorado passed House Bill 16-1426 which criminalized the misrepresentation of service animals.  On the surface, this seems like a good idea but it is not / wasn't.  Not only has it failed to stop the misrepresentation of Service Animals as it was intended, it has increased the frequency of refusal of access and the intensity of the confrontations.  As they did nothing to increase the enforcement of the ADA laws, they have only further empowered business owners to increase their discrimination.
           </p>

           <p><br></br></p>


           <p>
             <br></br><b>Colorado Revised Statutes Title 24 - Government - State Principal Departments</b>
             <br></br><b>Title 24 Article 34 - Department of Regulatory Agencies</b>
             <br></br> 24-34 Part 3 Colorado Civil Rights Division - Commission - Procedures
             <br></br> 24-34-301 Definitions
             <br></br> 24-34-306 Charge - complaint - hearing - procedure - exhaustion of administrative remedies
             <br></br>
             <br></br> 24-34 Part 4 Employment Practices
             <br></br>
             <br></br> 24-34 Part 5 Housing Practices
             <br></br>
             <br></br> 24-34 Part 6 Discrimination in Places of Public Accommodation
             <br></br> 24-34-601 Discrimination in places of public accommodation - definition
             <br></br> 24-34-602 Penalty and civil liability
             <br></br> 24-34-603 Jurisdiction of county court - trial
             <br></br> 24-34-604 Time limits on filing charges
             <br></br> 24-34-605 Relief authorized
             <br></br>
             <br></br> 24-34 Part 8 Persons with Disabilities - Civil Rights
             <br></br> 24-34-801 Legislative declaration
             <br></br> 24-34-802 Violations - penalties
             <br></br> 24-34-803 Rights of individuals with service animals
             <br></br> 24-34-804 Service animals - violations - penalties
             <br></br>
             <br></br><b>Colorado Revised Statutes Title 18 - Criminal Code</b>
             <br></br><b>Title 18 Article 13 - Miscellaneous Offenses</b>
             <br></br> 18-13-107. Interference with persons with disabilities

             <br></br>
           </p>


           <p>
             <br></br>
           </p>

           <p>
             <br></br><b>Resources</b>
             <br></br>
             <br></br><a href="https://www.denvergov.org/content/dam/denvergov/Portals/643/documents/Offices/Disability/Tri-Fold%20ADA%20Brochure%20FINAL%20PDF.pdf">Pamphlet from Denver.gov on Service Animals</a>
             <br></br>
             <br></br><a href="https://www.ada.gov/regs2010/service_animal_qa.html">Service Animal Q&A on ADA.gov</a>
             <br></br>
             <br></br><a href="https://disabilitylawco.org/issues/category/service-assistance-animals">Service Animals vs. ESAs</a> 
             <br></br>
             <br></br><b>Emotional Support Animals</b>
             <br></br>
             <br></br><a href="https://www.animallaw.info/article/faqs-emotional-support-animals#s2">ESAs F.A.Q.</a>
             <br></br>
             <br></br><a href="https://www.9news.com/article/news/local/one-year-later-is-the-law-banning-fake-service-dogs-in-colorado-working/491326578">Results of House Bill 16-1426</a>
             <br></br>
           </p>

           <p><br></br></p>


           <p>
             <br></br><b>Documented Service Animal Refusals of a Disabled Veteran</b>
             <br></br>
             <br></br>August 2016, SBs, Pueblo, Colorado
             <br></br>
           </p>

           <p>
             <br></br>July 4th, 2016, Good Earth Meds, Pagosa Springs, Colorado
             <br></br>
             <br></br>Bill Delany, Physically and verbally assaulted a patron while denying access to facility on the account of a service animal.  A real fine thank you from a hypocrite who not only claims to support and thank the veterans, but is a veteran himself.  On July 4th of all days.
             <br></br>
             <br></br>When this individual attempted to speak out and draw awareness to the law and need for education concerning it, he was met with ridicule and bigotry by Bill Hudson of the Pagosa Daily Post.  <a href="https://pagosadailypost.com/2016/07/22/editorial-beware-of-dog/">Pagosa 'opinion' piece</a>.
             <br></br>
           </p>

           <p>
             <br></br>August 20th, 2015, Travelodge, Des Moines, Iowa
             <br></br>
             <br></br>Stephanie Nicole, Denied hotel room that was prepaid and clearly noted there were service animals on the invoice.
           </p>

           <p>
             <br></br>July 24th, 2014, LCC, Alamosa, Colorado
             <br></br>
             <br></br>Refused service to disabled veteran for not providing certification paperwork, twice despite being provided the law in printed format and material from the ADA.
           </p>


<p><br></br></p>


           { this.renderArticleFooter( ) }

           <p><br></br></p>

           { this.renderSubscribeForm( ) }

           <p><br></br></p>

         </div>
      );
    }
  }
}


export default RefusalOfServiceAnimals;

