import React, { Component } from 'react';
import '../../App.css';



class SubscribeForm extends Component {


  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      emailAddress: '',
      siteUpdates: true,
      artUpdates: true
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    this.handleEmailChange = this.handleEmailChange.bind( this );
    this.handleSiteCheckboxChange = this.handleSiteCheckboxChange.bind( this );
    this.handleArtCheckboxChange = this.handleArtCheckboxChange.bind( this );

    this.handleSubmit = this.handleSubmit.bind( this );

  }


  handleNChange( event ) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState( { [name]: value } );
  }


  handleEmailChange( event ) {

    this.setState( { emailAddress: event.target.value } );
  }

  handleSiteCheckboxChange( event ) {

    this.setState( { siteUpdates: !this.state.siteUpdates } );
  }
  handleArtCheckboxChange( event ) {

    this.setState( { artUpdates: !this.state.artUpdates } );
  }

  isEmailUnique( onSuccess, onFail ) {

    return new Promise( ( resolve, reject ) => {

      var rqst = 'https://eccep.co/backend/MailList/isEmailUnique';

      fetch( rqst, {

        method: 'POST',
        mode: 'cors',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify( {
          emailaddress: this.state.emailAddress
        })

      })
      .then( resp => resp.json( ) )
      .then( function( resp ) {
        resolve( resp );
      })
      .catch( function( err ) {
        console.log( 'Error: ' + err );
        reject( err );
      });

    } )

  }

  subscribe( onSuccess, onFail ) {

    return new Promise( ( resolve, reject ) => {

      var rqst = 'https://eccep.co/backend/MailList/subscribe';

      fetch( rqst, {

        method: 'POST',
        mode: 'cors',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify( {
          emailaddress: this.state.emailAddress
        })

      })
      .then( resp => resp.json( ) )
      .then( function( resp ) {
        resolve( resp );
      })
      .catch( function( err ) {
        console.log( 'Error: ' + err );
        reject( err );
      });

    } )
  }


  handleSubmit( event ) {

    event.preventDefault();

    //var eAddr = event.target.emailAddress.value;
    //is Email Unique?
    this.isEmailUnique( )
      .then( resp => {

        if( resp > 0 ) {
          console.log( "Record already exists!" );
        }
        else {
          this.subscribe( );
        }
      } )
      //.then( function( resp ) {
      //  console.log( "emailAddress: ", eadr );
        //numRecs = [resp.length]
      //} )
      .catch( function( err ) {
        console.log( 'Error: ' + err );
      });

  }


  render( ) {

    return(

      <div >

        <form className="subscribeForm" id="subscribeForm" name="subscribeForm" onSubmit={ this.handleSubmit } >

          <table id="subscribeTable" name="subscribeTable" >
            <tbody>
              <tr>
                <td colSpan="4" >
                Never miss an article!  Subscribe
                </td>
              </tr>
              <tr>
                <td colSpan="4" >
                  <label>
                    Email Address:
                    <input
                      id="emailAddress"
                      name="emailAddress"
                      type="text"
                      value={ this.state.emailAddress }
                      onChange={ this.handleEmailChange } />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Site</label>
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="siteUpdates"
                    checked={ this.state.siteUpdates }
                    onChange={ this.handleSiteCheckboxChange.bind(this) } />
                </td>
                <td>
<label>Arts</label>
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="artUpdates"
                    checked={ this.state.artUpdates }
                    onChange={ this.handleArtCheckboxChange.bind(this) } />
                </td>
              </tr>
              <tr>
                <td colSpan="4" >
                  <input type="submit" value="Subscribe" onClick={ this.handleSubmit } />
                </td>
              </tr>


            </tbody>
          </table>
        </form>

      </div>
    );
  }
}

export default SubscribeForm;