

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';



var loggedOn = false;




class RenewablePowerCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

             <Helmet>
               <title>{ this.state.title }</title>
               <meta name="keywords" content="Alamosa Colorado Project Renewable Power" />
               <meta name="description" content="Alamosa Colorado Renewable Power" />
             </Helmet>


           <p><b>Renewable Power</b></p>

           <p>
             <br></br><u>Renewable Power</u>
             <br></br>
             <br></br>50 kW Renewable generated, battery free reliable power
             <br></br>
             <br></br>Batteries are just one of the negative aspects to renewable power.  Our team of engineers have developed solutions that minimizes the hazardous waste by reducing battery usage by 90%, or eliminating them altogether.
           </p>

         </div>

      );

    }
  }
}


export default RenewablePowerCPane;
