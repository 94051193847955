
import React, { Component } from 'react';

import '../../App.css';



import AlamosaArticlesCPane from './AlamosaArticlesCPane.js';



class AlamosaArticlesCenterPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
   
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }

  renderAlamosaArticlesCPane( ) {
    return <AlamosaArticlesCPane page={ this.state.page } title={ this.state.title } />;
  }


  render( ) {

    if( this.state.page === 'art-alamosa' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderAlamosaArticlesCPane( ) }
        </div>

      );
    }

    else {
      return(

        <div id="centerPaneDiv">

          <table id="CenterPaneTable">
            <tbody>
              <tr>
                <td>



                </td>

              </tr>

            </tbody>
          </table>

        </div>
      );
    }
  }
}


export default AlamosaArticlesCenterPane;
