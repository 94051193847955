

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';



import '../App.css';



var loggedOn = false;




class BusinessOfPoverty extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
         <div>

             <Helmet>
               <title>{ this.state.title }</title>
               <meta name="keywords" content="Business Of Poverty business of poverty" />
               <meta name="description" content="The Business of Poverty" />
             </Helmet>

<h2>{ this.state.title }</h2>



         </div>
      );
    }
  }
}


export default BusinessOfPoverty;
