

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';



var loggedOn = false;




class EducationandTrainingCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

             <Helmet>
               <title>{ this.state.title }</title>
               <meta name="keywords" content="Alamosa Colorado Project Education and Training" />
               <meta name="description" content="Alamosa Colorado Education and Training" />
             </Helmet>


           <p><b>Education and Training</b></p>

           <p>
             <br></br><u>Education and Training</u>
             <br></br>
             <br></br>
           </p>

         </div>

      );

    }
  }
}


export default EducationandTrainingCPane;
