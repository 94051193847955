
import React, { Component } from 'react';
import '../App.css';


class SiteFooter extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    return(

      <div  style={{ textAlign: 'center' }}>

          <table id="footerTable" >
            <tbody>
              <tr>
                <td id="footerULTD" >

                </td>
                <td id="footerUCTD" >
                  <a href="/TermsOfService">Terms</a> | <a href="/PrivacyPolicy">Privacy</a> | < a href="/Sitemap">Sitemap</a> | <a href="/AboutUs">About</a>
                </td>
                <td id="footerURTD" >

                </td>
              </tr>
              <tr>
                <td >

                </td>
                <td  id="footerLCTD" >
                  &copy; 2019 ECCEP.co
                </td>
                <td id="footerLRTD" >

                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default SiteFooter;