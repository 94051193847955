
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';


import ArticleHeader from '../../Site/Components/ArticleHeader.js';
import ArticleFooter from '../../Site/Components/ArticleFooter.js';
import SubscribeForm from '../../Site/Components/subscribeForm.js';




class ACDeniesPropertyAccess extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "J.Smith";
this.state.pubDate = "7.2.19";

  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderSubscribeForm( ) {
    return <SubscribeForm />
  }

  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
        <div>

             <Helmet>
             <title>{ this.state.title }</title>
               <meta name="keywords" content="Alamosa County Denies Property Access Disabled Veteran alamosa county denies property access disabled veteran" />
               <meta name="description" content="Alamosa County Denies Property Access Disabled Veteran alamosa county denies property access disabled veteran" />
             </Helmet>



          <h2>{ this.state.title }</h2>

          <p><br></br></p>

          <p>ECCEP.co began inquiring to the necessary details and attempted to apply for an address beginning in 2013 on rural property we purchased off of a State highway.  For over four years ( five agricultural seasons ), Alamosa County Land Use Department refused to issue an address on the property.  This prevented us from gaining legal access and farming the property in accordance with all State laws and County ordinances.  You can read about Alamosa County's "Code of the West" here.  <a href="https://www.colorado.gov/pacific/alamosacounty/atom/66031">Resolution 2000-G-010</a>.</p>

          <p>Why would one even need an address to access, let alone farm their property?  Colorado Department of Transportation ( CDOT ).  According to CDOT's application for access ( easement ) off of a State or Federal highway requires an address ( for the property to be serviced ) and a phone number.  You can read <a href="https://www.codot.gov/library/forms/cdot0137.pdf">CDOT's State Highway Access Permit Application</a> here.</p>

          <p>One also needs an address in Alamosa County in order to access the services such as Fire, EMS, Police, etc.  This unnecessairly creates a safety risk while preventing meaningful property development.</p>

          <p>After researching the matter further, we found it interesting the fact that <a href="http://dariusallen.org">Commissioner [Darius] Allen</a> directly had a hand in passing Alamosa County's "Code of the West" legislation, and he is the one who seemingly was most adamant about refusing an address or correcting the issues this has exposed.  <a href="http://michaelyohn.org">Michael Yohn</a> to this date continues to sit idly, never once engaging us about our concerns despite numerous emails and trips to the Commissioners' meetings.  And he has seen us several times since in person yet can not even bring himself to apologize for the harm he and the corrupt Council members have caused.</p>

          <p>It ultimately took six phone calls, five in-person trips to the Land Use Department, several emails and two trips to County Commissioners' meetings to get Alamosa County Land Use Department to accept an application for an address.</p>

          <p>Shortly after we attended the Alamosa County Commissioners' meetings, we received an email from <a href="http://jasontkelly.org">Jason T. Kelly</a> stating he had no clue why an address was needed to farm the property and falsely claimed that having water on the property was a requirement for property access.  Jason T. Kelly's ignorance coupled with the fact this guy is a licensed attorney is completely perplexing.  It is improper, not to mention immoral to hold an entire County hostage to the ignorance and lack of imagination of one man.  It is sheer lawlessness to not follow the laws Alamosa County drafted and passed themselves.</p>

          <p><br></br></p>

          <p><blockquote cite="">"Taxation without representation is tyranny."</blockquote> James Otis, 1761</p>

          <p><br></br></p> 

          <p>When we attempted to have these issues addressed, all of the Alamosa County Commissioners ignored and dismissed these problems.  Helen Sigmond was the only Commissioner to actually engage on any issue, months after the fact.  On January 24th, 2019 She simply indicated that Alamosa County considered this matter resolved, confirming they have simply dismissed the problems identified.</p>

          <p><a href="http://dariusallen.org">Darius Allen</a> attempted to censor us in one of the Commissioners' meetings and <a href="http://michaelyohn.org">Michael Yohn</a> blocked us on Facebook.  Both are examples of political censorship, just one traditional characteristic of dictators and authoritarian regimes.</p>

          <p><br></br></p>

          <p>"Thank You for Your Service!" / "We Support Our Veterans"</p>

          <p>As an organization created and run by disabled veterans, ECCEP.co finds it highly offensive to have been denied legal access to the property for over four years.  Meanwhile, Alamosa County is of course charging property taxes largely in part to fund County services; Fire, EMS, Police etc.  However, since one needs an address to both access the property and the services these taxes fund, they are forcing people to pay for services they are being denied from receiving.  This is fraud.  You can read the Oxford's <a href="https://www.oxfordlearnersdictionaries.com/us/definition/english/fraud">definition of fraud here</a>.  If you ever attempt to address a grievance with this authoritarian and corrupt set of commissioners, they simply try to silence your voice.  Borderline taxation without representation?</p>

          <blockquote cite="http://avalon.law.yale.edu/18th_century/fed79.asp">"In the general course of human nature, A POWER OVER A MAN's SUBSISTENCE AMOUNTS TO A POWER OVER HIS WILL."</blockquote><a href="http://avalon.law.yale.edu/18th_century/fed79.asp">Alexander Hamilton, The Federalist Papers, no 79</a>.

          <blockquote cite="http://press-pubs.uchicago.edu/founders/documents/v1ch16s23.html">"Government is instituted to protect property of every sort; as well that which lies in the various rights of individuals, as that which the term particularly expresses. This being the end of government, that alone is a just government, which impartially secures to every man, whatever is his own."</blockquote><a href="http://press-pubs.uchicago.edu/founders/documents/v1ch16s23.html">James Madison, doc 23, chpt 16 Property</a>

          <blockquote cite="https://www.archives.gov/founding-docs/bill-of-rights-transcript">"<span style={{ background: "yellow" }} >The right of the people to be secure in their persons, houses, papers, and effects, against unreasonable searches and seizures, shall not be violated</span>, and no Warrants shall issue, but upon probable cause, supported by Oath or affirmation, and particularly describing the place to be searched, and the persons or things to be seized."</blockquote><a href="https://www.archives.gov/founding-docs/bill-of-rights-transcript">U.S Constitution, Fourth Amendment</a>

          <blockquote cite="https://www.archives.gov/founding-docs/bill-of-rights-transcript">"No person shall be held to answer for a capital, or otherwise infamous crime, unless on a presentment or indictment of a Grand Jury, except in cases arising in the land or naval forces, or in the Militia, when in actual service in time of War or public danger; nor shall any person be subject for the same offence to be twice put in jeopardy of life or limb; nor shall be compelled in any criminal case to be a witness against himself, <span style={{ background: "yellow" }} >nor be deprived of life, liberty, or property, without due process of law; nor shall private property be taken for public use, without just compensation.</span>"</blockquote><a href="https://www.archives.gov/founding-docs/bill-of-rights-transcript">US Constitution, Fifth Amendment</a>

          <blockquote cite="https://www.archives.gov/founding-docs/bill-of-rights-transcript">"<span style={{ background: "yellow" }} >All persons born or naturalized in the United States, and subject to the jurisdiction thereof, are citizens of the United States and the State wherein they reside. No State shall make or enforce any law which shall abridge the privileges or immunities of citizens of the United States; nor shall any State deprive any person of life, liberty, or property, without due process of law; nor deny to any person within its jurisdiction the equal protection of the laws.</span>"</blockquote><a href="https://www.archives.gov/founding-docs/bill-of-rights-transcript">US Constitution, Fourteenth Amendment</a>

          <p><br></br></p>

          <p>"Hypocritical Commissioners"</p>

          <p><a href="http://dariusallen.org">Darius Allen</a>, <a href="http://michaelyohn.org">Michael Yohn</a> and <a href="http://helensigmond.org">Helen Sigmond</a> are all three directly responsible for costing the county and San Luis Valley countless economic potential and revenue all because they believe they can legally deny a property owner the right to even access their own property, let alone farm it in accordance with County and State ordinances explicitly giving property owners the right to do just that.</p>

          <p>Alamosa County and the San Luis Valley needs new leadership.</p>

          <p><br></br></p>

          { this.renderArticleFooter( ) }

          <p><br></br></p>

          { this.renderSubscribeForm( ) }

          <p><br></br></p>

        </div>
      );
    }
  }
}


export default ACDeniesPropertyAccess;
