
import React, { Component } from 'react';
import '../App.css';



import TermsOfServiceCPane from './TermsOfServiceCPane.js';
import PrivacyPolicyCPane from './PrivacyPolicyCPane.js';
import AboutUsCPane from './AboutUsCPane.js';
import SitesCPane from './SitesCPane.js';
import SitemapCPane from './SitemapCPane.js';

//import RegistrationCPane from './registrationCPane.js';
//import LoginCPane from './loginCPane.js';
//import LogoutCPane from './logoutCPane.js';



class SiteCenterPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
   
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }

  renderTermsOfServiceCPane( ) {
    return <TermsOfServiceCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title }/>;
  }
  renderPrivacyPolicyCPane( ) {
    return <PrivacyPolicyCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderAboutUsCPane( ) {
    return <AboutUsCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderSitesCPane( ) {
    return <SitesCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderSitemapCPane( ) {
    return <SitemapCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }



  render( ) {

    if( this.state.page === 'Site' ) {

      if( this.state.title === 'ECCEP' ) {
        return(

          <div id="centerPaneDiv">

            <blockquote cite="">"There may be times when we are powerless to prevent injustice, but there must never be a time when we fail to protest.", Elie Wiedel</blockquote>



<p><br></br></p>


          <p>
            <br></br>Welcome to the ECCEP.co homepage!
          </p>


<p><br></br></p>


          <p>
            <br></br>In our <a href="/Articles">articles section</a> you can read about what is happening in Southern Colorado.
          </p>

          <p>
            <br></br>Or you can read about why we were founded and our mission in the <a href="/AboutUs">About Us</a> section.
          </p>


<p><br></br></p>

<p><br></br></p>

          </div>
        );
      }
      else if( this.state.title === 'TermsOfService' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderTermsOfServiceCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'PrivacyPolicy' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderPrivacyPolicyCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'AboutUs' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderAboutUsCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Sites' ) {
        return(
          <div>
            { this.renderSitesCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Sitemap' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderSitemapCPane( ) }
          </div>
        );
      }
    }

    else {
      return(

        <div id="centerPaneDiv">


"There may be times when we are powerless to prevent injustice, but there must never be a time when we fail to protest."  Elie Wiedel, Dec 11, 1986

            Welcome to the ECCEP.co homepage!

          <p>You can read more about why we were founded and our mission in the <a href="/AboutUs">About Us</a> section.</p>

          <p></p>

        </div>
      );
    }//End else ( default )
  }//end if( this.state.


} // end render


export default SiteCenterPane;
