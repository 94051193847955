

import React, { Component } from 'react';
import '../App.css';


import LeftPane from './LeftPane.js';
import CenterPane from './CenterPane.js';
import RightPane from './RightPane.js';



class ContentPanel extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
      
    };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    //this.handleChange = this.handleChange.bind( this );

    //this.handleSubmit = this.handleSubmit.bind( this );
  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }

  renderLeftPane( ) {
    return <LeftPane page={ this.state.page } />;
  }

  renderCenterPane( ) {
    return <CenterPane page={ this.state.page } title={ this.state.title } />;
  }

  renderRightPane( ) {
    return <RightPane page={ this.state.page } />;
  }


  render( ) {

    return(

      <div id="contentPanelDiv" valign="top">

          <table id="centerPaneTable">
            <tbody>
              <tr>
                <td>

                  { this.renderLeftPane( ) }

                </td>
                <td>

                  { this.renderCenterPane( ) }

                </td>
                <td>

                  { this.renderRightPane( ) }

                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default ContentPanel;
