
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';


import ArticleHeader from '../../Site/Components/ArticleHeader.js';
import ArticleFooter from '../../Site/Components/ArticleFooter.js';
//import SubscribeForm from '../../Site/Components/subscribeForm.js';




class Esperantoland extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "C.Everhart";
this.state.pubDate = "13.7.2019";

  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  //renderSubscribeForm( ) {
  //  return <SubscribeForm />
  //}

  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
        <div>

             <Helmet>
             <title>{ this.state.title }</title>
               <meta name="keywords" content="Esperantoland EsperantoSLV Alamosa San Luis Valley SLV esperantoland esperantoslv alamosa san luis valley" />
               <meta name="description" content="Esperantoland in the San Luis Valley" />
             </Helmet>



          <h2>{ this.state.title }</h2>

          <p><br></br></p>

*Translated from Esperanto.  To read the original Esperanto version of this article, please click <a href="https://esperantoslv.wordpress.com/2019/07/13/nova-esperanta-lernejo-kaj-bieno-new-esperanto-learning-center-and-farm/">HERE</a>.*

<p>
The members of EsperantoSLV have great news. We have a place where we hope to construct an Esperanto learning center and library.  The land is located besides Highway 160, about 10 miles east of Alamosa, CO.
</p>

<p>
There's not anything on the land yet.  It's simply beautiful desert without utilities or infrastructure.
</p>

<p>
We hope to begin constructing the project this coming spring. What do we hope to build there?  What do we hope to do there?
</p>

<p>
-We want to build a farm.  There Esperantists will be able to work together.  The land has good water rights. We can grow between 4 and 6 acres of plants. In Colorado we have, "The Right to Farm Act."  For that reason, we're absolutely allowed to farm.
</p>
<p>
-We will breed animals.  Many ranches already exist in our valley.  We could choose what kinds of animals we want to ranch.  We can ranch ducks, camels, goats, or other animals. We're definitely allowed to do that according to the law.  We can also sell animals or products from the animals, such as cheese.
</p>
<p>
-We can build a house.  There can live some Esperantists who want to live and work together.
</p>
<p>
-Outside of the house, helpers can live at the farm for up to six months of the year.  We could build small buildings.  This is a good opportunity for those who don't want to live in Colorado during winter or who simply want to visit. 
</p>
<p>
-Each year, we want to host Esperantists. We want to create a new Esperanto meet-up.  The working name of the event is, "The Most Awesome Animal Party."  That name exists because of a puppet show that happened at NASK 2019.
</p>
<p>
-Another thing might require government permission.  To build a huge Esperanto learning center and library, we have to ask permission from the government.  Another possibility is to construct a small learning center and library near the house, or in the house. 
</p>

<p>
Now we have land and dreams.  In the future, we dream that we will have a home where Esperanto Land always will exist. 
</p>

<p>
If you want to help, volunteer, or support the project, please contact Esperanto SLV through their <a href="https://esperantoslv.wordpress.com/contact/">contact page</a>.
</p>


          <p><br></br></p>

          { this.renderArticleFooter( ) }

          <p><br></br></p>

          <p><br></br></p>

        </div>
      );
    }
  }
}


export default Esperantoland;
