
import React, { Component } from 'react';
import '../../App.css';


class ArticleFooter extends Component {


  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''

    };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

  }


  handleChange( event ) {
    this.setState( { [event.target.name]: event.target.value } );
  }


  render( ) {

    return(

      <div>

          <table id="ArticleFooterTable">

            <tbody>
              <tr>
                <td>
                  Title:
                </td>
                <td colSpan="5" >
                 { this.state.title }
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td>
                  Author:
                </td>
                <td colSpan="2" >
                 { this.state.author }
                </td>
                <td>
                  Published:
                </td>
                <td colSpan="2" >
                 { this.state.pubDate }
                </td>
                <td>
                </td>
              </tr>
            </tbody>

          </table>

      </div>
    );
  }
}

export default ArticleFooter;