

import React, { Component } from 'react';

import '../../App.css';


import AlamosaArticlesLeftPane from './AlamosaArticlesLeftPane.js';
import AlamosaArticlesCenterPane from './AlamosaArticlesCenterPane.js';
import AlamosaArticlesRightPane from './AlamosaArticlesRightPane.js';



class AlamosaArticlesContentPanel extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
      
    };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    //this.handleChange = this.handleChange.bind( this );

    //this.handleSubmit = this.handleSubmit.bind( this );
  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }

  renderAlamosaArticlesLeftPane( ) {
    return <AlamosaArticlesLeftPane page={ this.state.page } />;
  }

  renderAlamosaArticlesCenterPane( ) {
    return <AlamosaArticlesCenterPane page={ this.state.page } title={ this.state.title } />;
  }

  renderAlamosaArticlesRightPane( ) {
    return <AlamosaArticlesRightPane page={ this.state.page } />;
  }


  render( ) {

    return(

      <div id="contentPanelDiv" valign="top">

          <table id="centerPaneTable">
            <tbody>
              <tr>
                <td>

                  { this.renderAlamosaArticlesLeftPane( ) }

                </td>
                <td>

                  { this.renderAlamosaArticlesCenterPane( ) }

                </td>
                <td>

                  { this.renderAlamosaArticlesRightPane( ) }

                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default AlamosaArticlesContentPanel;
