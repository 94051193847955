

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../App.css';


import ArticleHeader from '../Site/Components/ArticleHeader.js';
import ArticleFooter from '../Site/Components/ArticleFooter.js';

import  SubscribeForm from '../Site/Components/subscribeForm.js';



class CCICensorship extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "J.Smith";
this.state.pubDate = "10.2.19";


  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderSubscribeForm( ) {
    return <SubscribeForm />
  }
  DocumentMeta( ) {
    this.document.title = "Colorado Counties Inc. Practices Political Censorship";
  }

  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
         <div>

           <Helmet>
             <title>{ this.state.title }</title>
             <meta name="keywords" content="Colorado Counties Inc. Corruption Censorship Tax Fraud colorado counties inc. corruption censorship tax fraud" />
             <meta name="description" content="Colorado Counties Inc. Corruption Censorship Tax Fraud" />
           </Helmet>



           <h2>{ this.state.title }</h2>

           <img src="/Images/CCI-Logo2.jpg" class="center" width="50%" height="50%" alt="Colorado Counties Inc. promoting corruption in Local, County and State governments"></img>


           <p>
             <a href="https://ccionline.org/">Colorado Counties Inc.</a> is a fine example of how the corruption of local and County governments are not only perpetuated but emboldened and empowered by those at the top.
           </p>

           <p>
             In an attempt to have grievances redressed about the corruption, discrimination and political censorship in Alamosa County ironically, <a href="https://ccionline.org/">Colorado Counties Inc.</a> decided to exercise political censorship of their own against a disabled veteran.
           </p>

           <blockquote cite="https://en.wikipedia.org/wiki/Near_v._Minnesota">Hughes quoted Madison in the majority decision, writing, "<span style={{ background: "yellow" }} >The impairment of the fundamental security of life and property by criminal alliances and <b>official neglect emphasizes the primary need of a vigilant and courageous press"</b></span>.[219]</blockquote>
           <a href="https://en.wikipedia.org/wiki/Near_v._Minnesota">Near vs. Minnesota ( 1931 )</a>, bold emphasis added

           <p>
             The problem is <a href="https://ccionline.org/">Colorado Counties Inc.</a> claims to be a <b>private company of Lobbyists</b> that has subjected themselves to public discourse as they work <b>influencing legislation at the Local, County and State level governments</b>.  Legislation and the activities related to are public activities making thier posts subject to regulation by the First Ammendment.  They are trying to play both sides.  However, through their own publications, they self identify as an <b>organization that works to influence legislation that effects every citizen</b>.  Addressing our government and influencing legislation is a right granted upon <b>every</b> citizen of the United States.  Colorado Counties Inc. is trying to remove any input or influence on decision making from the individual in a shroud of secrecy.
           </p>

           <blockquote cite="ccionline.org">"CCI is a non-profit, membership association whose purpose is to offer assistance to county commissioners, mayors and council members and to <span style={{ background: "yellow" }} >encourage counties to work together on common issues.  Governed by a board of directors consisting of eight commissioners from across the state, our focus is on information, education and <b>legislative representation</b>.  We strive to keep our members up-to-date on issues that directly impact county operations.  At the same time, <b>we work to present a united voice to the Colorado General Assembly and other government and regulatory bodies to help shape the future</b></span>."</blockquote>
           <a href="http://ccionline.org">from ccionline.org</a>, main page, bold emphasis added

           <p><br></br></p>

           <p>
             <br></br><a href="https://eccep.co/Articles/Colorado-Counties-Inc-Suspected-Tax-Fraud">Colorado Counties Inc. Suspected of Tax Fraud</a>
           </p>

           <p><br></br></p>

           <blockquote cite="">"In August 2017, the ACLU filed suit in a federal district court in Maryland, <span style={{ background: "yellow" }} >claiming the deletion of negative comments about the governor was free-speech censorship</span>. The Maryland settlement comes as the Knight First Amendment Institute at Columbia University is challenging President Donald Trump's blocking of negative comments and users of his public Twitter account, also on First Amendment grounds. Arguments in that case where heard in early March in New York City, where a judge urged both sides to reach a compromise solution.
             U.S. District Judge Naomi Reice Buchwald suggested that President Trump "mute" Twitter users he found offensive, <span style={{ background: "yellow" }} ><b>so he wouldn't see their messages as they remained available to other users</b>. Trump's attorneys argued his personal Twitter account isn't a public forum subject to First Amendment regulations, an idea that the Knight First Amendment Institute strongly opposed.</span>"
           </blockquote>
             You can read <a href="https://www.rollcall.com/news/politics/aclu-challenges-lawmakers-block-people-social-media">more on rollcall.com here</a>, bold emphasis added

           <p>
             <br></br>It is also clearly stated Colorado Counties Inc.'s bylaws that:
           </p>

           <blockquote cite="http://ccionline.org/download/Bylaws-final-adopted-06-05-18.pdf">

ARTICLE II
Objects, Purposes and Powers
Section 1. Enumerated. The objects and purposes of this Corporation are and shall be, by
association, to cultivate a more general knowledge and to encourage a greater interest among the
counties of the State of Colorado in the administration of county government; to consider and, by
discussion and united action, work toward the solution of the many financial, administrative,
<span style={{ background: "yellow" }} ><b>legislative</b>, road construction and maintenance, social services and other problems which exist in
connection with <b>county and local government, in the interest of the people of the respective </b> 
counties, and the State of Colorado; and, further:
(1) To preserve, promote and strengthen county and local units of government;
(2) To combat any movement that has as its objective centralization of government in large
units that will take from the American people, if any</span>;
<span style={{ background: "yellow" }} >(3) To cooperate with national and state departments so that the taxpayers will receive full value
for <b>their tax dollars</b>;</span>
(4) To develop a high level of service so that economy and efficiency consistent with good
management principles can be practiced;
<span style={{ background: "yellow" }} >(5) To establish and support a medium for the exchange of ideas and experience of county
officials throughout the state and nation to be used in working toward a solution of county
problems</span>;
(6) To institute and promote conventions and publications for the education and instruction of
county officials in the latest and most up-to-date methods of administration and record keeping;
<span style={{ background: "yellow" }} >(7) To serve faithfully <b>the people of our respective counties</b>;</span>
(8) To identify and address issues which are unique to urban and/or rural counties and <b>work
with the legislature and other jurisdictions</b> to respond to those issues; and
(9) To <b>improve legislative awareness and responsiveness</b> to urban and/or rural impacts.</blockquote>

           <a href="http://ccionline.org/download/Bylaws-final-adopted-06-05-18.pdf">read Colorado Counties Inc. bylaws here</a>, bold emphasis added.


           <p>
             <a href="https://ccionline.org/">Colorado Counties Inc.</a> facade disintegrates once you notice that they are violating their own bylaws.  As they claim to combat any movement that will take from the American people, they are fighting themselves.
           </p>


           <p>
             Now we have private companies so bold as to try and tell others what is best for them by advising legislative policy?  They are attempting to advise how to spend your tax dollars without any concern for the average citizen who foots their bills.
           </p>

<blockquote cite="https://dspace.lboro.ac.uk/dspace-jspui/bitstream/2134/22289/3/Parvin_renewal%20piece%20on%20lobbying%20final%20prepub.pdf">"...<b>Power that rightfully belonged to the British
people</b> had been moved upwards to the EU and other supranational institutions, and <b>downwards to
local and regional quangos all of which operated beyond the conventional checks and balances our
system has established in order to ensure transparency and accountability</b>. Meanwhile, in the middle
between the local and supranational, <b>central institutions</b> had become diminished in power and
authority, and also <b>secretive and insular</b>. <span style={{ background: "yellow" }} >The further democratic institutions (and the people who
worked in them) had retreated from the people, the easier it had become for MPs to falsify their
expenses, for <b>corporate lobbyists</b> to strike deals with elected politicians, and for decisions to be made 
behind closed doors</span>. What political power remained at the centre had become concentrated among a 
<b>cosy clique of politicians and powerful vested interests</b>."</blockquote>
You can read the full article here on the Loughborough University's publication here. < a href="https://dspace.lboro.ac.uk/dspace-jspui/bitstream/2134/22289/3/Parvin_renewal%20piece%20on%20lobbying%20final%20prepub.pdf">Silencing the critics: Charities, lobbyists, and the government's quiet war on dissent</a>, bold emphasis added
           <p>
             <br></br><b>Just what exactly is public discourse and why does it matter anyway?</b>
           </p>

<blockquote cite="http://discourses.org/OldArticles/What%20is%20Political%20Discourse%20Analysis.pdf">"There is another complication, which is associated with the very delimitation
of the field of politics. Obviously, it is <b>not only official or professional politics and
politicians</b> that are involved in the polity. <b>Political activity and the political
process also involve people as citizens and voters, people as members of pressure
and issue groups, demonstrators and dissidents, and so on</b> (Verba, et al., 1993). <span style={{ background: "yellow" }} >All
these <b>groups and individuals, as well as their organizations and institutions, may
take part in the political process, and many of them are actively involved in
political discourse</b>.</span> That is, a broad defmition of politics implies a vast extension
of the scope of the term 'political discourse' if we identify such practices by all
participants in the political process.

Another, but overlapping way of delimiting the object of study is by <span style={{ background: "yellow" }} >focusing
on the <b>nature of the activities or practices being accomplished by political text and
talk rather than only on the nature of its participants</b>.</span> That is, even politicians are
not always involved in political discourse, and the same is obviously true for most
other participants, such as the public or citizens in general, or even members of
social movements or action groups. This also means that categorization of people
and groups should at least be strict, viz., <b>in the sense that their members are
participants of political discourse only when acting as political actors, and hence
as participating in political actions, such as governing , ruling, legislating,
protesting, dissenting, or voting. Specifically interesting for PDA is then that many
of there political actions or practices are at the same time discursive practices</b>. In
other words, forms of text and talk in such cases have political functions and
implications."</blockquote>
You can read the complete article here on discourses.org here.  <a href="http://discourses.org/OldArticles/What%20is%20Political%20Discourse%20Analysis.pdf">What is Political Discourse Analysis</a>, bold emphasis added

<p>
  So if political discourse is protected by the First Amendment, then on what authority might they rightfully be able to censor dissent?  This bring us to the topic of protected speech.  Not all forms of speech are protected under the First Amendment.  While profanity can not be made strictly illegal, the context and usage primarily governs whether it is a form of protected speech or not.  Fighting words, or words said specifically to incite an emotional response also depend on the context and usage to determine whether they are protected.
</p>

<p>
  That being said, none of the social media posts made by the disabled veteran contained any profanity or fighting words.  Allison Daley chose to remove the comments and block the veteran against their own 'social media policy' and in what seems a clear case of political censorship.  Whether you agree or not, to stifle the voice of a disabled veteran, who has demonstrably proven that they already paid a higher price than most for these freedoms, that you are so willing to casually and carelessly take them away because you disagree with their opinion is incredibly shady and immoral.
</p>

<p>
  While being provocational, there is no wrong in calling someone out for their political actions, decisions or effects of legislation that an elected official, acting as a public figure and political actor caused, created or influenced.  This is the very definition of political discourse.  Part of the claim is that the Alamosa County Commissioners' follow corrupt practices utilizing political censorship as a tool to silence dissent.  <a href="https://ccionline.org/">Colorado Counties Inc.</a> furthers this culture of corruption elevating and validating it to the top levels of State government which proves the problem is in fact systemic throughout Colorado.
</p>


<blockquote cite="">"The idea that states use restrictions on the free press to suppress dissent and to
mobilize support is widely accepted. Liberal philosophers and human rights advocates
have traditionally mounted a strong defense of an unfettered and independent press, as
embodied in the fundamental freedoms of expression, information, thought, speech and
conscience. <b>These principles are widely recognized as human rights in all major
conventions agreed by the world's leaders, including the 1948 UN Universal Declaration
of Human Rights, the European Convention on Human Rights, the American Convention
on Human Rights, and the African Charter on Human and Peoples' Rights</b>. Article 19 of
the 1948 Universal Declaration of Human Rights states: <span style={{ background: "yellow" }} >"Everyone has the right to
freedom of opinion and expression; this right includes freedom to hold opinions without
interference and to seek, receive and impart information and ideas through any media
and regardless of frontiers.</span>" Rights to freedom of the press are widely recognized as 
 <b>essential</b> in procedural definitions of representative democracy, for example Dahl (1971)
emphasized that citizens <b>cannot</b> make meaningful choices in contested elections without
access to alternative sources of information. <span style={{ background: "yellow" }} >The prevention of <b>corruption and abuse of
power by public officials also requires transparency</b>, so that the public can evaluate the 
outcome of government actions and hold elected representatives to account</span> (Islaam
2002, 2003)."</blockquote>
You can read the full article on harvard.edu's publication here.<a href="https://sites.hks.harvard.edu/fs/pnorris/Acrobat/Silencing%20dissent.pdf">SILENCING DISSENT </a>, bold emphasis added

           <p>
             We find it very telling that <a href="https://ccionline.org/">Colorado Counties Inc.</a> practices political censorship against those who are engaging on the topics of censorship and corruption in government.  The very act of censoring political discourse is corruption. 
           </p>

           <blockquote cite="https://en.wikipedia.org/wiki/First_Amendment_to_the_United_States_Constitution">"The Petition Clause <span style={{ background: "yellow" }} >protects the right "to petition the government for a redress of grievances"</span>.[204] <span style={{ background: "yellow" }} ><b>This includes the right to communicate with government officials, lobbying government officials and petitioning the courts by filing lawsuits with a legal basis</b></span>.[212] The Petition Clause first came to prominence in the 1830s, when Congress established the gag rule barring anti-slavery petitions from being heard; the rule was overturned by Congress several years later. Petitions against the Espionage Act of 1917 resulted in imprisonments. The Supreme Court did not rule on either issue.[234]"</blockquote>

           <blockquote cite="https://en.wikipedia.org/wiki/First_Amendment_to_the_United_States_Constitution">"In California Motor Transport Co. v. Trucking Unlimited,[235] the Supreme Court stated that the right to petition <span style={{ background: "yellow" }} >encompass "the approach of citizens or groups of them to administrative agencies (which are both creatures of the legislature, and arms of the executive) and to courts, the third branch of Government. Certainly the right to petition extends to all departments of the Government. The right of access to the courts is indeed but one aspect of the right of petition."[236] Today thus this right encompasses petitions to all three branches of the federal government-the Congress, the executive and the judiciary-and has been extended to the states through incorporation</span>.[234][237] According to the Supreme Court, <span style={{ background: "yellow" }} >"redress of grievances" is to be construed broadly: it includes not solely appeals by the public to the government for the redressing of a grievance in the traditional sense, but also, petitions on behalf of private interests seeking personal gain.[238] <b>The right not only protects demands for "a redress of grievances" but also demands for government action</b></span>.[234][238] The petition clause includes according to the Supreme Court the opportunity to institute non-frivolous lawsuits and mobilize popular support to change existing laws in a peaceful manner.[237]"</blockquote>
           You can read <a href="https://en.wikipedia.org/wiki/First_Amendment_to_the_United_States_Constitution">more on wikipedia.org here</a>, bold emphasis added


           <blockquote cite="">"...The sovereign is completely discharged from a duty, in the attempting to perform which he must always be exposed to innumerable delusions, and for the <span style={{ background: "yellow" }}>proper performance of which <b>no human wisdom or knowledge could ever be sufficient</b>; the duty of superintending the <b>industry of private people</b>, and of directing it towards the employments most suitable to the interest of the society</span>."</blockquote>
           Adam Smith, The Wealth of Nations, Chapter IX, p. 749, bold emphasis added.

           <p>
             <br></br><b>And just who are these people trying to decide our lives for us, without accepting input, feedback or political discourse?</b>
           </p>

           <p>
             In short, <a href="https://ccionline.org/">Colorado Counties Inc.</a> is a group of hypocritical lobbyists that claim to be for the people while their true goal seems to be censoring the individual citizen's voice in an attempt to shore up corruption in Local and County governments.  Through their own words, reinforced by self-determined actions, <a href="https://ccionline.org/">Colorado Counties Inc.</a> has demonstrated it is willing to violate the rights protected by the Constitution of the U.S.  This is not faithfully representing the people of the Counties they serve.  You can never serve someone if you are unwilling to listen to their concerns, ideas or needs.

             <blockquote cite="">"(5) To establish and support a medium for the exchange of ideas and experience of county officials throughout the state and nation to be used in working toward a solution of county problems;
             ...(7) To serve faithfully the people of our respective counties;"</blockquote>
           </p>

           <p><br></br></p>

           <p>
             Our request for comment to <a href="https://ccionline.org/">Colorado Counties Inc.</a> was responded to by Allison Daley with an admission that they did in fact censor political discourse against the First Amendment, while attempting to deny it is an infringement on the First Amendment.  You can <a href="/Colorado-Counties-Inc-Censorship-Response.html">read their admission here</a>.
           </p>

           <p><br></br></p>

           <p><br></br></p>

           { this.renderArticleFooter( ) }

           <p><br></br></p>

           { this.renderSubscribeForm( ) }

           <p><br></br></p>

         </div>
      );
    }
  }
}


export default CCICensorship;

