


import React, { Component } from 'react';
import '../App.css';



class TopNavSubMenu extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    return(

      <div id="topNavSubMenuDiv">

          <table id="topNavSubMenuTable">
            <tbody>
              <tr>
                <td>

                </td>
              </tr>
              <tr>
                <td>

                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default TopNavSubMenu;