
import React, { Component } from 'react';


import '../App.css';


import CommunitiesCPane from '../Communities/CommunitiesCPane.js';
import EsperantoSLVCommunityCPane from '../Communities/EsperantoSLV/EsperantoSLVCPane.js';

import ProjectsCPane from '../Projects/ProjectsCPane.js';
import AlamosaProjectsCPane from '../Projects/Alamosa/AlamosaProjectsCPane.js';

import TermsOfServiceCPane from './TermsOfServiceCPane.js';
import PrivacyPolicyCPane from './PrivacyPolicyCPane.js';
import AboutUsCPane from './AboutUsCPane.js';
import SitesCPane from './SitesCPane.js';
import SitemapCPane from './SitemapCPane.js';

import RegistrationCPane from './registrationCPane.js';
import LoginCPane from './loginCPane.js';
import LogoutCPane from './logoutCPane.js';



class CenterPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
   
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  renderRegistrationCPane( ) {
    return <RegistrationCPane page={ this.state.page } />;
  }
  renderLoginCPane( ) {
    return <LoginCPane page={ this.state.page } />;
  }
  renderLogoutCPane( ) {
    return <LogoutCPane page={ this.state.page } />;
  }

  renderCommunitiesCPane( ) {
    return <CommunitiesCPane page={ this.state.page } title={ this.state.title } />;
  }
  renderEsperantoSLVCommunityCPane( ) {
    return <EsperantoSLVCommunityCPane page={ this.state.page } title={ this.state.title } />;
  }
  renderProjectsCPane( ) {
    return <ProjectsCPane page={ this.state.page } />;
  }
  renderAlamosaProjectsCPane( ) {
    return <AlamosaProjectsCPane page={ this.state.page } />;
  }

  render( ) {

    if( this.state.page === 'Communities' ) {

      if( this.state.title === 'EsperantoSLV' ) {

        return(
          <div id="centerPaneDiv">

	    { this.renderEsperantoSLVCommunityCPane( ) }

          </div>
        );

      }
      else {

        return(

          <div id="centerPaneDiv">

	    { this.renderCommunitiesCPane( ) }

          </div>

        );
      }
    } //end if( this.state.page === 'Communities' )

    else if( this.state.page === 'projects' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderProjectsCPane( ) }
        </div>

      );
    }
    else if( this.state.page === 'proj-alamosa' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderAlamosaProjectsCPane( ) }
        </div>

      );
    }


    else if( this.state.page === 'plans' ) {
      return(

        <div id="centerPaneDiv">
        </div>

      );
    }


    else if( this.state.page === 'articles' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderArticlesCPane( ) }
        </div>

      );
    }

    else if( this.state.page === 'users' ) {
      return(

        <div id="centerPaneDiv">

        </div>

      );
    }
    else if( this.state.page === 'login' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderLoginCPane( ) }
        </div>

      );
    }
    else if( this.state.page === 'logout' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderLogoutCPane( ) }
        </div>

      );
    }
    else if( this.state.page === 'registration' ) {
      return(

        <div id="centerPaneDiv">
          { this.renderRegistrationCPane( ) }
        </div>

      );
    }




    else {
      return(

        <div id="centerPaneDiv">

          <table id="CenterPaneTable">
            <tbody>
              <tr>
                <td>



                </td>

              </tr>

            </tbody>
          </table>

        </div>
      );
    }
  }
}


export default CenterPane;
