
import React, { Component } from 'react';

import '../App.css';



var loggedOn = false;



class PrivacyPolicyCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
         <div>
           <br></br><b>ECCEP.co Privacy Policy</b>
           <p>
             ECCEP.co may collect names, email addresses and areas of interest for use within the ECCEP organization.
           </p>

           <p>
             <br></br><b>Types of Information</b>
           </p>


           <p>
             <br></br><b>Purpose of Data Collection</b>
           </p>

           <p>When you visit ECCEP.co, our servers collect various performance service metrics in an effort to offer an improved viewing experience.</p>
           <p>Our subscription services are strictly opt-in.  If you do not opt-in, we will never collecct, sell, store or use your information in a permenant manner.</p>

           <p>
             <br></br><b>Newsletter</b>
           </p>
           <p>
             In order to communicate updates on articles and the website, ECCEP.co offers an opt-in subscription system.
           </p>
           <p>
             When you opt-in, we collect your name, email address and topics of interest to better personalize or otherwise not annoy you with unwanted updates.
           </p>

           <p>
             <br></br><b>Data Security</b>
           </p>
           <p>
             When you connect to ECCEP.co, your web browser is forced to use TLS encrypted communications.  This is to protect your data as it is transmitted from computer to computer.  The use of TLS encryption requires the use of sessions which track connection information and redirect traffic to the appropriate servers.  All data transmissions occur strictly over encrypted channels.  This however means we can not guarentee that information is not collected on our site.  Data access, storage and transfers are strictly regulated by the Privacy Policy.
           </p>

           <p>
             <br></br><b>Data Storage</b>
           </p>
           <p>
             We do not permenantly retain cookie information unless you have subscribed to our newsletter.
           </p>
           <p>
             If you have registered with our website, we store the personal information you provide in addition to storing various usage and performance metrics in order to improve your site experience.
           </p>
           <p>
             If you have subscribed to our newsletter, we store various communication preferences.
           </p>

           <p>
             <br></br><b>Data Access</b>
           </p>
           <p>
             <br></br><b>Details of Data Transfers</b>
           </p>
           <p>
             At no point do we intentionally expose, lease, rent or sell your information to outside or third parties except as otherwise noted in the Terms of Service and this Privacy Policy.
           </p>

           <p>
             <br></br><b>Afiliated Websites / Organizations</b>
           </p>
           <p>
             ECCEP.co is affiliated with the ECCEP family of community organizations.  We share information with our affiliates in order to improve user experience across the family of organization websites and update our user community if opted-in.
           </p>

           <p>
             <br></br><b>Use of Cookies</b>
           </p>
           <p>
             ECCEP.co uses cookie and session tracking information.  We do not permenantly store or retain cookie information.
           </p>

           <p>
             <br></br><b>Do Not Track Requests</b>
           </p>
           <p>
             We presently do not honor Do Not Track Requests.
           </p>

         </div>
      );
    }
  }
}


export default PrivacyPolicyCPane;
