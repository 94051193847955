

import React, { Component } from 'react';

import '../../App.css';



var loggedOn = false;




class AlamosaProjectsCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

           <p><b>ECCEP.co Alamosa Projects:</b></p>
           <p>
             <br></br><u>Alamosa Sustainable Community</u>
           </p>
           <p><b>Onhold indefinitely due to corrupt County Government.  Sorry Alamosa, we know you need the help!  Progress first starts with telling your local leaders the status quo they created isn't working for you and voting for change.</b></p>

           <p>
             <br></br><a href="/Projects/Alamosa/HolisticSystem">Holistic System</a>
           </p>
           <p>We feel that there are three core pillars to a thriving community.  Together they form a symbiotic relationship where the suffering of one affects the group as a whole.
           </p>
           <p>
             <br></br>People
             <br></br>Animals
             <br></br>Land
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/GeneratingProsperity">Generating Prosperity</a>
           </p>
{ /*
           <p>The key to generating prosperity is production.  Before any resource can be taken or taxed away, it must first be produced.  Production is the key to all the wealth in the world that exists, or ever will be. 
           </p>
           <p>
             Once value has been produced, it is important to maximize the effects of that productivity.  The Federal government claims that for every $1 injected in the economy, as it passes hands it generates up to $7 in taxable economic activity.  
           </p>
           <p>
             This also works in reverse.  For every $1 in goods that a community produces for itself, means there is up to $7 in economic activity freed up.  WIth a focus on animals and agriculture, a community can create prosperity simply with their own labor and access to land while largely providing their own food security.
           </p>
*/ }

           <p>
             <br />The key to generating prosperity is production.  Before any resource can be taken or taxed away, it must first be produced.  Production is the key to all the wealth in the world that exists, or ever will be. 
             <br />
             <br />Once value has been produced, it is important to maximize the effects of that productivity.  By providing the needs of the community locally and in a more reliable yet, cost effective option not only facilitates trade and production, it also incentivizes support of local businesses.
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/BuildingCommunity">Building Community</a>
             <br></br>
             <br></br>
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/EducationandTraining">Education and Training</a>
             <br></br>
             <br></br>
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/QualityJobs">Quality Jobs</a>
             <br></br>
             <br></br>

           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/RestoringtheLand">Restoring the Land</a>
             <br></br>
             <br></br>Through proper care and management it is a rather simple matter to restore the land.  With a holistic approach to the land and animals, improvements to the soil are a natural consequence to agriculture.
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/AutomatedAgriculture">Automated Agriculture System</a>
             <br></br>
             <br></br>ECCEP.co has partnered with the engineers at <a href="https://sps-development.com">SpS-Development</a> to validate their large scale automated agriculture system.  I.R.M.A. is an intelligent automation control system capable of increasing agricultural yields by 15% while consuming up to 55% less water.  You can read more about <a href="https://sps-development.com/Products/IRMA">I.R.M.A. on SpS-Development.com</a> here.
           
           </p>
           <p>
</p>

           <p>
             <br></br><a href="/Projects/Alamosa/Animals">Animals</a>
             <br></br>
             <br></br>Before chemical fertilizers, for centuries the world over used animals as a vital component to managing and ammending the soil.  In addition to restoring the land, animals also produce raw materials for basic necessities of life.
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/CommercialKitchen">Commercial Kitchen</a>
             <br></br>
             <br></br>On-site USDA inspected food processing facility to minimize waste, create local food stuffs and provide employment in the community.
           </p>

           <p>
             <br></br><a href="/Projects/Alamosa/RenewablePower">Renewable Power</a>
             <br></br>
             <br></br>50 kW Renewable generated, battery free reliable power
             <br></br>
             <br></br>Batteries are just one of the negative aspects to renewable power.  Our team of engineers have developed solutions that minimizes the hazardous waste by reducing battery usage by 90%, or eliminating them altogether.
           </p>

           <p>
             <br /><a href="/Projects/Alamosa/BusinessIncubator">Business Incubator</a>
             <br />
             <br />Dedicated voice solutions provided by on-site equipment.
             <br />
             <br />Renewable powered on-site fault tollerant 10 Gbps fiber fed network designed and maintained by <a href="https://augustindataservices.com">Augustin Data Services</a>.  With locally hosted data services, buiness will never suffer completely from a valleywide network outage.  Local businesses will always be able to connect and conduct their affairs independently of the outside world.
           </p>

           <p>
             <br />
           </p>

         </div>

      );

    }
  }
}


export default AlamosaProjectsCPane;
