
import React, { Component } from 'react';
import '../../App.css';



import AlamosaProjectsCPane from './AlamosaProjectsCPane.js';
import HolisticSystemCPane from './HolisticSystemCPane.js';
import GeneratingProsperityCPane from './GeneratingProsperityCPane.js';
import BuildingCommunityCPane from './BuildingCommunityCPane.js';
import EducationandTrainingCPane from './EducationandTrainingCPane.js';
import QualityJobsCPane from './QualityJobsCPane.js';
import AutomatedAgricultureCPane from './AutomatedAgricultureCPane.js';
import RestoringtheLandCPane from './RestoringtheLandCPane.js';
import AnimalsCPane from './AnimalsCPane.js';
import CommercialKitchenCPane from './CommercialKitchenCPane.js';
import RenewablePowerCPane from './RenewablePowerCPane.js';
import BusinessIncubatorCPane from './BusinessIncubatorCPane.js';



class AlamosaProjectsCenterPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
   
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }

  renderAlamosaProjectsCPane( ) {
    return <AlamosaProjectsCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderHolisticSystemCPane( ) {
    return <HolisticSystemCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderGeneratingProsperityCPane( ) {
    return <GeneratingProsperityCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderBuildingCommunityCPane( ) {
    return <BuildingCommunityCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderEducationandTrainingCPane( ) {
    return <EducationandTrainingCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderQualityJobsCPane( ) {
    return <QualityJobsCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderRestoringtheLandCPane( ) {
    return <RestoringtheLandCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderAutomatedAgricultureCPane( ) {
    return <AutomatedAgricultureCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderAnimalsCPane( ) {
    return <AnimalsCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderCommercialKitchenCPane( ) {
    return <CommercialKitchenCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderRenewablePowerCPane( ) {
    return <RenewablePowerCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }
  renderBusinessIncubatorCPane( ) {
    return <BusinessIncubatorCPane userID={ this.state.userID } page={ this.state.page } title={ this.state.title } />;
  }




  render( ) {

    if( this.state.page === 'alamosa-projects' ) {

      if( this.state.title === 'projects' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderAlamosaProjectsCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Holistic System' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderHolisticSystemCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Generating Prosperity' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderGeneratingProsperityCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Building Community' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderBuildingCommunityCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Education and Training' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderEducationandTrainingCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Quality Jobs' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderQualityJobsCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Restoring the Land' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderRestoringtheLandCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Automated Agriculture' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderAutomatedAgricultureCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Animals' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderAnimalsCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Commercial Kitchen' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderCommercialKitchenCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Renewable Power' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderRenewablePowerCPane( ) }
          </div>
        );
      }
      else if( this.state.title === 'Business Incubator' ) {
        return(
          <div id="centerPaneDiv">
            { this.renderBusinessIncubatorCPane( ) }
          </div>
        );
      }

    }

    else {
      return(

        <div id="centerPaneDiv">


        </div>
      );
    }//End else ( default )
  }//end if( this.state.


} // end render


export default AlamosaProjectsCenterPane;
