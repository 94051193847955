

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';



var loggedOn = false;




class BusinessIncubatorCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (

         <div>

             <Helmet>
               <title>{ this.state.title }</title>
               <meta name="keywords" content="Alamosa Colorado Project Commercial Kitchen" />
               <meta name="description" content="Alamosa Colorado Commercial Kitchen" />
             </Helmet>


           <p><b>Business Incubator</b></p>

           <p>
             <br></br><u>Business Incubator</u>
             <br></br>
             <br></br>Renewable powered on-site fault tollerant 10 Gbps fiber fed network designed and maintained by <a href="https://augustindataservices.com">Augustin Data Services</a>.  With locally hosted data services, buiness will never suffer completely from a valleywide network outage.  Local businesses will always be able to connect and conduct their affairs independently of the outside world.
           </p>

           <p><br /></p>

           <p>
             <br />Enterprise class Voice over Internet Protocol ( VoIP )
             <br />
             <br />Enterprise class 10 Gbps redundant fiber fed private network
             <br />
             <br />On-site renewable generated power
           </p>

           <p><br /></p>

         </div>

      );

    }
  }
}


export default BusinessIncubatorCPane;
