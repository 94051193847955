

import React, { Component } from 'react';

import '../App.css';



var loggedOn = false;




class ProjectsCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {
      return (

         <div>

           <p><b>ECCEP.co Projects:</b></p>
           <p><a href="/Projects/Alamosa/">Alamosa</a></p>

           <p>ECCEP.org - Costa Rica</p>
           <p>Due to the corruption of Alamosa County, we have put the Alamosa Project on hold indefinitely.  This however will not stop us from working towards our mission.  We have partnered up with ECCEP.org and will be taking our experience, ingenuity and knowledge to Costa Rica!  You can follow our progress here.</p>

         </div>

      );
    }
  }
}


export default ProjectsCPane;
