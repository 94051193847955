
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


import '../../App.css';


//import  SubscribeForm from '../../Site/Components/subscribeForm.js';


//import AlamosaArticlesCPane from './AlamosaArticlesCPane.js';
//import ACDeniesPropertyAccess from './Alamosa-County-Denies-Property-Access-To-Disabled-Veteran.js';
//import ACProtectsUnethicalAttorney from './Alamosa-County-Protects-Unethical-Attorney.js';
//import ARHatePoorPeople from './Alamosa-Republicans-Hate-Poor-People.js';
//import ACDeniesPropertyAccess from './Alamosa-County-Denies-Property-Access-To-Disabled-Veteran.js';
import Esperantoland from './Esperantoland.js';




const cpaneStyle = {
  margin: 0,
  position: 'relative',
  top: '50%',
  transform: 'translate( 0%, -5%)',
  msTransform: 'translate(0%, -5%)'

};

const subDivStyle = {
  margin: 0,
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate( -25%, 00%)',
  msTransform: 'translate(-25%, 00%)'

};




class EsperantoSLVArticlesCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;


    //fetch articles & article information
    this.state.author = "J.Smith";
    this.state.pubDate = "7.4.19";

    this.state.cat = "";
    this.state.rootCat = "";
    this.state.subCat = "";

  }


  handleChange( ) {

  }

  //renderSubscribeForm( ) {
  //  return <SubscribeForm />
  //}

  renderEsperantoland( ) {
    return <Esperantoland userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }


  render() {

    if( this.state.page === 'art-esperanto' ) {

      if( this.state.title === 'Esperantoland' ) {

        return (
          <div>
            { this.renderEsperantoland( ) }
          </div>
        );

      }//end if


  
      else {

        return (

          <div style={ cpaneStyle } >


             <Helmet>
               <title>ECCEP.co - EsperantoSLV Articles</title>
               <meta name="keywords" content="ECCEP.co - EsperantoSLV Articles" />
               <meta name="description" content="ECCEP.co Articles EsperantoSLV Colorado San Luis Valley articles esperantoslv colorado san luis valley" />
             </Helmet>


            <p><br></br></p>

            <p><b>Articles EsperantoSLV:</b></p>

            <p><br></br></p>

            <a href="/Articles/EsperantoSLV/Esperantoland">Esperantoland</a>

            <p><br></br></p>


            <p><br></br></p>

          </div>
        );
      } // end else ( default )
    }//end if

  }//end render
}

export default EsperantoSLVArticlesCPane;
