

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';



import '../App.css';


import ArticleHeader from '../Site/Components/ArticleHeader.js';
import ArticleFooter from '../Site/Components/ArticleFooter.js';

import  SubscribeForm from '../Site/Components/subscribeForm.js';


class CCITaxFraud extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "J.Smith";
this.state.pubDate = "pending";


  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderSubscribeForm( ) {
    return <SubscribeForm />
  }


  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
         <div>

             <Helmet>
               <title>{ this.state.title }</title>
               <meta name="keywords" content="Colorado Counties Inc. Tax Fraud colorado counties inc. tax fraud" />
               <meta name="description" content="Colorado Counties Inc. Tax Fraud" />
             </Helmet>


           <h2>{ this.state.title }</h2>

           <img src="/Images/CCI-Logo2.jpg" class="center" width="50%" height="50%" alt="Colorado Counties Inc. promoting corruption in Local, County and State governments"></img>

           <p>
             <a href="https://ccionline.org/">Colorado Counties Inc.</a> is a fine example of how the corruption of local and County governments are not only perpetuated but emboldened and empowered by those at the top.
           </p>

           <p>
             In an attempt to have grievances redressed about the corruption, discrimination and political censorship in Alamosa County ironically, <a href="https://ccionline.org/">Colorado Counties Inc.</a> decided to exercise political censorship of their own against a disabled veteran.
           </p>

           <blockquote cite="https://en.wikipedia.org/wiki/Near_v._Minnesota">Hughes quoted Madison in the majority decision, writing, "<span style={{ background: "yellow" }} >The impairment of the fundamental security of life and property by criminal alliances and <b>official neglect emphasizes the primary need of a vigilant and courageous press"</b></span>.[219]</blockquote>
           <a href="https://en.wikipedia.org/wiki/Near_v._Minnesota">Near vs. Minnesota ( 1931 )</a>, bold emphasis added

           <p>
             The problem is <a href="https://ccionline.org/">Colorado Counties Inc.</a> claims to be a <b>private company of Lobbyists</b> that has subjected themselves to public discourse as they work <b>influencing legislation at the Local, County and State level governments</b>.  Legislation and the activities related to are public activities making thier posts subject to regulation by the First Ammendment.  They are trying to play both sides.  However, through their own publications, they self identify as an <b>organization that works to influence legislation that effects every citizen</b>.  Addressing our government and influencing legislation is a right granted upon <b>every</b> citizen of the United States.  Colorado Counties Inc. is trying to remove any input or influence on decision making from the individual in a shroud of secrecy.
           </p>

           <blockquote cite="ccionline.org">"CCI is a non-profit, membership association whose purpose is to offer assistance to county commissioners, mayors and council members and to <span style={{ background: "yellow" }} >encourage counties to work together on common issues.  Governed by a board of directors consisting of eight commissioners from across the state, our focus is on information, education and <b>legislative representation</b>.  We strive to keep our members up-to-date on issues that directly impact county operations.  At the same time, <b>we work to present a united voice to the Colorado General Assembly and other government and regulatory bodies to help shape the future</b></span>."</blockquote>
           <a href="http://ccionline.org">from ccionline.org</a>, main page, bold emphasis added


           <blockquote cite="https://en.wikipedia.org/wiki/501(c)_organization#501(c)(4)">"According to The Washington Post, 501(c)(4) organizations:[55]
            ...are <span style={{ background: "yellow" }} >allowed to participate in politics, so long as politics do not become their primary focus. What that means in practice is that they <b>must spend less than 50 percent of their money on politics</b>.</span> So long as they don't run afoul of that threshold, the groups can influence elections, which they typically do through advertising."
           </blockquote>
           You can read more on wikipedia.org here about <a href="https://en.wikipedia.org/wiki/501(c)_organization#501(c)(4)">501(c) Organization</a>, bold emphasis added

           <blockquote cite="https://www.irs.gov/charities-non-profits/lobbying">"<span style={{ background: "yellow" }} >In general, no organization may qualify for section 501(c)(3) status <b>if a substantial part of its activities is attempting to influence legislation (commonly known as lobbying)</b>.  A 501(c)(3) organization may engage in some lobbying, but <b>too much lobbying activity risks loss of tax-exempt status.
             Legislation includes action by Congress, any state legislature, any local council, or similar governing body, with respect to acts, bills, resolutions, or similar items (such as legislative confirmation of appointive office), or by the public in referendum, ballot initiative, constitutional amendment, or similar procedure</b></span>.  It does not include actions by executive, judicial, or administrative bodies.
             <b>An organization will be regarded as attempting to influence legislation if it contacts, or urges the public to contact, members or employees of a legislative body for the purpose of proposing, supporting, or opposing legislation, or if the organization advocates the adoption or rejection of legislation</b>.
           </blockquote>
           You can read more <a href="https://www.irs.gov/charities-non-profits/lobbying">here on the IRS lobbying rules concerning non-profits</a>, bold emphasis added.

           <p>
             <a href="https://www.irs.gov/charities-non-profits/measuring-lobbying-activity-expenditure-test">IRS Expenditure Test</a> you can see <a href="https://ccionline.org/">Colorado Counties Inc.</a> fails this test.  For the IRS Substantial Part Test, it would have to be determined by case review.
           </p>

           <p>
             Being a 'non-profit' corporation, IRS form 990 is required by law to be filed yearly and freely accessible.  A quick visit to Open990.com reveals that <a href="https://ccionline.org/">Colorado Counties Incc.</a> spent $895,925 dollars in fiscal year 2017, while bringing in $1.347 million.  Colorado Counties Inc. is spending 66.5%+ of their operating budget directly exerting or influencing County and State legislation.  Yet they fail to list any lobbying expenses.
              <a href="https://www.open990.com/org/840682884/colorado-counties-inc">www.open990.com</a>
           </p>


           <p>
             <a href="https://ccionline.org/">As Colorado Counties Inc.</a> focus is that "we work to present a united voice to the <b>Colorado General Assembly</b> and other <b>government and regulatory bodies</b> to help shape the future", this puts them in a similar category as a government official that opens up their social media pages and feeds by posting official or government business.  Furthermore, an elected official does not have a right to privacy in this sense as when they became elected officials they became what is known as public figures.  They have voluntarily given up their right to and are now regulated by the First Amendment as determined by the Supreme Court.  Their primary focus is to shape or otherwise influence lawmakers and legislation.
           </p>

           <p>
             <a href="https://ccionline.org/">Colorado Counties Inc.</a> has voluntarily chosen to engage in politics and legislation from the Local to the State levels, this would put them in violation of the "rule of thumb" Washing Post claims.  This also moves them squarely from the realm of private corporation to the public as every citizen is guaranteed their right to express grievances to politicians and elected officials to exert their own influence and determinations of what is best for them.
           </p>

           <p><br></br></p>

           { this.renderArticleFooter( ) }

           <p><br></br></p>

           { this.renderSubscribeForm( ) }

           <p><br></br></p>

         </div>
      );
    }
  }
}


export default CCITaxFraud;

