
import React, { Component } from 'react';


import '../App.css';



class ArticlesLeftPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
      
    };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {


      return(

          <div id="leftPaneDiv" >

            <table id="leftPaneTable" >
              <tbody>
                <tr>
                  <td>

                  </td>
                </tr>
              </tbody>
            </table>

          </div>
      );

  }
}


export default ArticlesLeftPane;
