

import React, { Component } from 'react';
//import { View, StyleSheet, Image, Text, Button } from 'react-native';
import { Helmet } from 'react-helmet';



import '../App.css';


import SubscribeForm from '../Site/Components/subscribeForm.js';

import PovertyByDesign from './PovertyByDesign.js';
import BusinessOfPoverty from './TheBusinessOfPoverty.js';

import CCICensorship from './CCICensorship.js';
import CCITaxFraud from './CCITaxFraud.js';

import RefusalOfServiceAnimals from './RefusalOfServiceAnimals.js';



const cpaneStyle = {
  margin: 0,
  position: 'relative',
  top: '50%',
  transform: 'translate( 0%, 0%)',
  msTransform: 'translate( 0%, 0%)'

};


class ArticlesCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

console.log( "page: ", this.state.page );
console.log( "title: ", this.state.title );

  }


  handleChange( ) {

  }

  renderSubscribeForm( ) {
    return <SubscribeForm />
  }

  renderPovertyByDesign( ) {
    return <PovertyByDesign page={ this.state.page } title={ this.state.title } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } author={ this.state.author } pubDate={ this.state.pubDate } updated={ this.state.updated } />
  }

  renderBusinessOfPoverty( ) {
    return <BusinessOfPoverty page={ this.state.page } title={ this.state.title } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } author={ this.state.author } pubDate={ this.state.pubDate } updated={ this.state.updated } />
  }
  renderCCICensorship( ) {
    return <CCICensorship page={ this.state.page } title={ this.state.title } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } author={ this.state.author } pubDate={ this.state.pubDate } updated={ this.state.updated } />
  }
  renderCCITaxFraud( ) {
    return <CCITaxFraud page={ this.state.page } title={ this.state.title } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } author={ this.state.author } pubDate={ this.state.pubDate } updated={ this.state.updated } />
  }
  renderRefusalOfServiceAnimals( ) {
    return <RefusalOfServiceAnimals page={ this.state.page } title={ this.state.title } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } author={ this.state.author } pubDate={ this.state.pubDate } updated={ this.state.updated } />
  }


  render() {



      if( this.state.page === 'articles' ) {

        if( this.state.title === 'Poverty By Design' ) {

          return (

            <div>

              { this.renderPovertyByDesign( ) }

            </div>

          );

        }//end if
        else if( this.state.title === 'The Business Of Poverty' ) {

          return (

            <div>

              { this.renderBusinessOfPoverty( ) }

            </div>

          );

        }//end else if
        else if( this.state.title === 'Colorado Counties Inc. Practices Political Censorship Against Disabled Veteran' ) {

          return (

            <div>

              { this.renderCCICensorship( ) }

            </div>

          );

        }//end else if
        else if( this.state.title === 'Colorado Counties Inc. Suspected of Tax Fraud' ) {

          return (

            <div>

              { this.renderCCITaxFraud( ) }

            </div>

          );

        }//end else if

        else if( this.state.title === 'Southern Colorado Refusal of Service Animals' ) {

          return (

            <div>

              { this.renderRefusalOfServiceAnimals( ) }

            </div>

          );

        }//end else if

        else {

          return (

           <div  style={ cpaneStyle } >

             <Helmet>
               <title>ECCEP.co - Articles</title>
               <meta name="keywords" content="ECCEP.co - Articles" />
               <meta name="description" content="ECCEP.co Articles Colorado articles colorado" />
             </Helmet>




             <p><br></br></p>

             <p><b>Articles About Colorado:</b></p>

             <p>Part of community building is informing the community of what occurs in the community.  While some stories are positive, others are critical, all of them are researched and rooted in facts.</p>

             <p><a href="/Articles/Southern-Colorado-Refusal-of-Service-Animals">Southern Colorado Refusal of Service Animals</a></p>

             <p><a href="/Articles/Colorado-Counties-Inc-Practices-Censorship">Colorado Counties Inc. Practices Politicial Censorship Against Disabled Veteran</a></p>

             <p><a href="/Articles/Colorado-Counties-Inc-Suspected-Tax-Fraud">Colorado Counties Inc. Suspected of Tax Fraud</a></p>


             <p><a href="/Articles/Poverty-By-Design">Poverty By Design</a></p>

             <p><a href="/Articles/The-Business-Of-Poverty">The Business of Poverty</a></p>

             <p><br></br></p>

             <p><b>Articles About Alamosa County:</b></p>

             <p><a href="/Articles/Alamosa-County/">Alamosa County Colorado</a></p>

             <p><br></br></p>

             { this.renderSubscribeForm( ) }

             <p><br></br></p>

           </div>

          );
        }//end else ( default )

    }
  }
}



export default ArticlesCPane;
