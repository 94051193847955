

import React, { Component } from 'react';


import '../App.css';


import ArticlesLeftPane from './ArticlesLeftPane.js';
import ArticlesCenterPane from './ArticlesCenterPane.js';
import ArticlesRightPane from './ArticlesRightPane.js';



class ArticlesContentPanel extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''
      
    };

    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    //this.handleChange = this.handleChange.bind( this );

    //this.handleSubmit = this.handleSubmit.bind( this );
  }


  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }

  renderArticlesLeftPane( ) {
    return <ArticlesLeftPane page={ this.state.page } />;
  }

  renderArticlesCenterPane( ) {
    return <ArticlesCenterPane page={ this.state.page } title={ this.state.title } />;
  }

  renderArticlesRightPane( ) {
    return <ArticlesRightPane page={ this.state.page } />;
  }


  render( ) {

    return(

      <div id="contentPanelDiv" valign="top">

          <table id="centerPaneTable">
            <tbody>
              <tr>
                <td>

                  { this.renderArticlesLeftPane( ) }

                </td>
                <td>

                  { this.renderArticlesCenterPane( ) }

                </td>
                <td>

                  { this.renderArticlesRightPane( ) }

                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default ArticlesContentPanel;
