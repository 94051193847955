
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';



import '../../App.css';



import ArticleHeader from '../../Site/Components/ArticleHeader.js';
import ArticleFooter from '../../Site/Components/ArticleFooter.js';
import SubscribeForm from '../../Site/Components/subscribeForm.js';



class ACProtectsUnethicalAttorney extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      cat: '',
      rootCat: '',
      subCat: '',
      author: '',
      pubDate: '',
      updated: ''
      

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;
    this.state.cat = props.cat;
    this.state.rootCat = props.rootCat;
    this.state.subCat = props.subCat;
    this.state.author = props.author;
    this.state.pubDate = props.pubDate;
    this.state.updated = props.updated;

this.state.author = "J.Smith";
this.state.pubDate = "7.2.19";


  }


  handleChange( ) {

  }

  renderArticleHeader( ) {
    return <ArticleHeader userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderArticleFooter( ) {
    return <ArticleFooter userID={ this.state.userID } page={ this.state.page } title={ this.state.title } author={ this.state.author } 
             pubDate={ this.state.pubDate } revision={ this.state.revision } cat={ this.state.cat } rootCat={ this.state.rootCat } subCat={ this.state.subCat } />;
  }
  renderSubscribeForm( ) {
    return <SubscribeForm />
  }

  render() {

    if( false )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
        <div>

             <Helmet>
               <title>Alamosa County Protects Unethical Attorney</title>
               <meta name="keywords" content="Alamosa County Protects Unethical Attorney Jason T. Kelly alamosa county protects unethical attorney jason t. kelly" />
               <meta name="description" content="Alamosa County protects unethical County Attorney Jason T. Kelly" />
             </Helmet>



          <h2>{ this.state.title }</h2>

          <p><br></br></p>

          "Protection of Dishonest and Unethical County Employees"

          <p>
            <a href="http://jasontkelly.org">Jason Todd Kelly</a> is a dishonest, unethical attorney currently representing Alamosa County.
          </p>

          <p>
            From 2014 through 2018, for four years Alamosa Country Land Use Department refused to issue or even accept a permit requesting an address. This effectively prevents any property owner holding land in Alamosa County that sits off of a state or federal highway, from legally accessing their property without being forced to pay for unnecessary permits in many cases. You can read the <a href="https://eccep.co/Articles/Alamosa-County/Alamosa-County-Denies-Property-Access-To-Disabled-Veteran">article here</a>.
          </p>

<p>
  Furthermore, it has been confirmed with the Alamosa Land Use Department that there are no fees required to access or farm one's own property with the exceptions of structures, fencing or industrial use cases. None of which applied to us. You can read the confirmation here. In fact, we were previously informed our plan involved strictly ( or very near ) Value Added Agriculture.
</p>

<p>
  Although we have come to an understanding and made apologies with the Land Use Department once they granted our request of scheduling the policy review in public hearing and simply apologizing while admitting it was a mistake / misunderstanding. At the meeting November 2018, the Council agreed and decided to correct these inconsistencies. Despite this, <a href="http://jasontkelly.org">Jason T. Kelly</a> continues to knowingly assert several false claims to the Alamosa County Commissioners in order to protect himself. When this was brought to their attention, <a href="http://dariusallen.org">Darius Allen</a>, <a href="http://michaelyohn.org">Michael Yohn</a>, and <a href="http://helensigmond.org">Helen Sigmond</a> apparently just blew it off. You would think after their past history with employing criminals they might take these matters more seriously?
</p>

<p>
  We then submitted a CORA request in order to provide them the proof they refused to look in to themselves. The CORA request was for log book records of in person visits, records of phone and email contacts, in addition to the emails and notes of the Commissioners during this debacle. All public record according the Colorado State regulations. We received a record of our own email contacts and in person visits only. Once again <a href="http://dariusallen.org">Darius Allen</a>, <a href="http://michaelyohn.org">Michael Yohn</a>, and <a href="http://helensigmond.org">Helen Sigmond</a> seemingly has dismissed the evidence and seriousness of the problem. One can only conclude that these Commissioners are putting personal feelings before the good of the County. By continuing to employ <a href="http://jasontkelly.org">Jason T. Kelly</a> without even addressing the issues demonstrates their complicity and should at the very least call in to question their judgement. Receiving advice from an unethical attorney, who is willing to lie in his official capacity, is bound to be disbarred if he were to lie in court, under oath.  You can read more about <a href="https://www.sos.state.co.us/pubs/info_center/cora.html">CORA requests on the Colorado Secretary of State website here</a>.
</p>

<p>
  <br></br><a href="https://eccep.co/Articles/Alamosa-County/Alamosa-County-Denies-Property-Access-To-Disabled-Veteran">Alamosa County Denies Legal Access To Property</a>
</p>

<p>
  On July 25th, 2018 this was brought to the attention of <a href="http://dariusallen.org">Darius Allen</a>, <a href="http://michaelyohn.org">Michael Yohn</a>, and <a href="http://helensigmond.org">Helen Sigmond</a>.
</p>

<p>
  Contrary to the false claim made by his wife Janet, <a href="http://michaelyohn.org">Michael Yohn</a> does not respond to every letter or phone call. He never once has engaged with us nor has he ever had the character to apologize once for his infringements on our Constitutionally protected Civil Rights or the effects of his actions. Read <a href="http://www.michaelyohn.org/Articles/Janet-Yohn-Bears-False-Witness.html">"Thou shalt not bear false witness"</a> for more information.
</p>

<p>
  In fact, our experiences with both <a href="http://michaelyohn.org">Michael Yohn</a> and <a href="http://dariusallen.org">Darius Allen</a> have been just the opposite. Both have attempted to censor dissent while speaking at the County Commissioners' meetings or by blocking our posts on Facebook. Something the Federal courts have already determined to be an infringement on the right to Freedom of Speech. You can read an article written by the ACLU on it here. You would think that a veteran, who claims to support veterans, would have a bit more concern for the rights we defended for all of the US.
</p>

<p>
  As <a href="http://michaelyohn.org">Michael Yohn</a> is a veteran of the US Army, this required him to swear an oath to uphold and protect the Constitution against all enemies foreign and domestic. You can read the US Army oath here. And being disabled veterans ourselves, we know all to well the oath.
</p>

<p>
  As elected officials in the state of Colorado, <a href="http://dariusallen.org">Darius Allen</a>, <a href="http://michaelyohn.org">Michael Yohn</a> and <a href="http://helensigmond.org">Helen Sigmond</a> all took an oath to abide by and protect the laws. It is clear that they have failed at even their most basic and fundamental charges; rule of law..
</p>

<p>
  <br></br>"Thank You for Your Service!" / "We Support Our Veterans"
</p>

<p>
  But <a href="http://dariusallen.org">Darius Allen</a> and <a href="http://michaelyohn.org">Michael Yohn</a> are bigots determined to hold people down by interfering in their lives improperly and without authority. This is the Alamosa Republican way after all. If they are willing to discriminate against disabled US Army veterans, while lying saying they support the veterans, shouldn't you be asking what wouldn't Alamosa County fail to do to you? 
</p>

          <p>
            <br></br>"No Taxation Without Representation"
          </p>
          <p>
            Is this hypocrisy or just more questionable values displayed by our elected officials?
          </p>

          <p><br></br></p>

          { this.renderArticleFooter( ) }

          <p><br></br></p>

          { this.renderSubscribeForm( ) }

          <p><br></br></p>

        </div>
      );
    }
  }
}


export default ACProtectsUnethicalAttorney;
