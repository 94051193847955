

import React, { Component } from 'react';
import '../App.css';


//import Leaderboard from '../Ads/Leaderboard.png';
////import ECCEP_logo from '../Images/ECCEP_logo_lrg.jpg';
//import ECCEP_logo from '../Images/ECCEP-logo-768px.jpg';


class SiteHeader extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: null,
      userID: '',
      page: '',
      title: '',
      curTime: ''
      
    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

    this.state.curTime = new Date();

  }

  componentDidMount( ) {
    this.intervalID = setInterval( () => {
      this.tick( );
    }, 60000 );
  }

  componentWillUnmount() {
    clearInterval( this.intervalID );
  }

  tick() {
    this.setState({ curTime: new Date() });
  }

  getDate( ) {
    var ct = new Date( this.state.curTime );

    var month = ct.getMonth( ) + 1;
    var day = ct.getDate( );
    var year = ct.getFullYear( );

    var str = month + "/" + day + "/" + year;

    return str;
  }

  handleMouseOver( event ) {

  }

  handleOnClick( event ) {

  }


  render( ) {

    return(

      <div id="headerDiv">

{/*
          <table id="topadTable" style={{ "border": 0, "padding": 0, "margin": 0 }} >
            <tbody>

              <tr>

                <td id="headerULTD">
                </td>

                <td >
                    <img src={Leaderboard} width="728" height="90" />
                </td>

                <td id="headerURTD">
                </td>

              </tr>

            </tbody>
          </table>
*/}

          <table style={{ "border": 0, "padding": 0, "margin": 0 }} >
            <tbody>
              <tr>
                <td id="headerULTD">

                  <a href="http://www.eccep.co">

                    <img src="/Images/ECCEP-logo-768px.jpg" />

                  </a>

                </td>
              </tr>

            </tbody>
          </table>

      </div>
    );
  }
}


export default SiteHeader;