import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import './App.css';



//Site Pages
//import SiteRouter from './Routes/siteRouter.js';
//import CommunitiesRouter from './Routes/communitiesRouter.js';
//import ProjectsRouter from './Routes/projectsRouter.js';
//import PlansRouter from './Routes/plansRouter.js';
//import ArticlesRouter from './Routes/articlesRouter.js';

import RegistrationPage from './Site/registrationPage.js';
import LoginPage from './Site/loginPage.js';
import LogoutPage from './Site/logoutPage.js';



import SitePage from './Site/SitePage.js';


import CommunitiesPage from './Communities/CommunitiesPage.js';
import EsperantoSLVCommunityPage from './Communities/EsperantoSLV/EsperantoSLVPage.js';


import ProjectsPage from './Projects/ProjectsPage.js';
import AlamosaProjectsPage from './Projects/Alamosa/AlamosaProjectsPage.js';


import PlansPage from './Plans/PlansPage.js';

import ArticlesPage from './Articles/ArticlesPage.js';
import EsperantoSLVArticlesPage from './Articles/EsperantoSLV/EsperantoSLVArticlesPage.js';
import AlamosaArticlesPage from './Articles/Alamosa/AlamosaArticlesPage.js';




class App extends Component {

  constructor(props) {

    super(props);

    this.state = {

      value: null,
      userID: '',
      page: '',
      width: 0,
      height: 0

    };

    this.state.userID = props.userID;
    this.state.page = props.page;

  }

  componentDidMount(){
    document.title = "ECCEP"
  }

  render() {
    return(
      <BrowserRouter>
        <div>


{/* Site Pages */}

          <Route exact={true} path='/' render={ () => (
            <div className={ "eccep" } >
              <SitePage userID={ this.state.userID } page={ "Site" } title={ "ECCEP" } />
            </div>
          )}/>

          <Route exact={true} path='/Users/Registration' render={ () => (
            <div className={ "registration" } >
              <RegistrationPage userID={ this.state.userID } page={ "registration" } />
            </div>
          )}/>
          <Route exact={true} path='/Users/Login' render={ () => (
            <div className={ "login" } >
              <LoginPage userID={ this.state.userID } page={ "login" } />
            </div>
          )}/>
          <Route exact={true} path='/Users/Logout' render={ () => (
            <div className={ "logout" } >
              <LogoutPage userID={ this.state.userID } page={ "logout" } />
            </div>
          )}/>


          <Route exact={true} path='/TermsOfService' render={ () => (
            <div className={ "termsofservice" } >
              <SitePage userID={ this.state.userID } page={ "Site" } title={ "TermsOfService" } />
            </div>
          )}/>
          <Route exact={true} path='/PrivacyPolicy' render={ () => (
            <div className={ "privacypolicy" } >
              <SitePage userID={ this.state.userID } page={ "Site" } title={ "PrivacyPolicy" } />
            </div>
          )}/>
          <Route exact={true} path='/AboutUs' render={ () => (
            <div className={ "aboutus" } >
              <SitePage userID={ this.state.userID } page={ "Site" } title={ "AboutUs" } />
            </div>
          )}/>
          <Route exact={true} path='/Sites' render={ () => (
            <div className={ "sites" } >
              <SitePage userID={ this.state.userID } page={ "Site" } title={ "Sites" } />
            </div>
          )}/>
          <Route exact={true} path='/Sitemap' render={ () => (
            <div className={ "sitemap" } >
              <SitePage userID={ this.state.userID } page={ "Site" } title={ "Sitemap" } />
            </div>
          )}/>


          <Route exact={true} path='/Communities/' render={ () => (
            <div className={ "Communities" } >
              <CommunitiesPage userID={ this.state.userID } page={ "Communities" } title={ "Communities" } />
            </div>
          )}/>
          <Route exact={true} path='/Communities/EsperantoSLV/' render={ () => (
            <div className={ "EsperantoSLV" } >
              <EsperantoSLVCommunityPage userID={ this.state.userID } page={ "Communities" } title={ "EsperantoSLV" } />
            </div>
          )}/>


          <Route exact={true} path='/Projects/' render={ () => (
            <div className={ "projects" } >
              <ProjectsPage userID={ this.state.userID } page={ "projects" } />
            </div>
          )}/>

          <Route exact={true} path='/Projects/Alamosa' render={ () => (
            <div className={ "projects" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "projects" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/HolisticSystem' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Holistic System" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/GeneratingProsperity' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Generating Prosperity" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/BuildingCommunity' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Building Community" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/EducationandTraining' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Education and Training" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/QualityJobs' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Quality Jobs" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/RestoringtheLand' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Restoring the Land" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/AutomatedAgriculture' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Automated Agriculture" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/Animals' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Animals" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/CommercialKitchen' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Commercial Kitchen" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/RenewablePower' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Renewable Power" } />
            </div>
          )}/>
          <Route exact={true} path='/Projects/Alamosa/BusinessIncubator' render={ () => (
            <div className={ "proj-alamosa" } >
              <AlamosaProjectsPage userID={ this.state.userID } page={ "alamosa-projects" } title={ "Business Incubator" } />
            </div>
          )}/>


          <Route exact={true} path='/Plans/' render={ () => (
            <div className={ "plans" } >
              <PlansPage userID={ this.state.userID } page={ "plans" } />
            </div>
          )}/>


          <Route exact={true} path='/Articles/' render={ () => (
            <div className={ "articles" } >
              <ArticlesPage userID={ this.state.userID } page={ "articles" } title={ "Main" } />
            </div>
          )}/>

          <Route exact={true} path='/Articles/Poverty-By-Design' render={ () => (
            <div className={ "articles" } >
              <ArticlesPage userID={ this.state.userID } page={ "articles" }  title={ "Poverty By Design" } />
            </div>
          )}/>
          <Route exact={true} path='/Articles/Southern-Colorado-Refusal-Of-Service-Animals' render={ () => (
            <div className={ "articles" } >
              <ArticlesPage userID={ this.state.userID } page={ "articles" } title={ "Southern Colorado Refusal of Service Animals" } />
            </div>
          )}/>
          <Route exact={true} path='/Articles/The-Business-Of-Poverty' render={ () => (
            <div className={ "articles" } >
              <ArticlesPage userID={ this.state.userID } page={ "articles" } title={ "The Business Of Poverty" } />
            </div>
          )}/>
          <Route exact={true} path='/Articles/Colorado-Counties-Inc-Practices-Censorship' render={ () => (
            <div className={ "articles" } >
              <ArticlesPage userID={ this.state.userID } page={ "articles" } title={ "Colorado Counties Inc. Practices Political Censorship Against Disabled Veteran" } />
            </div>
          )}/>
          <Route exact={true} path='/Articles/Colorado-Counties-Inc-Suspected-Tax-Fraud' render={ () => (
            <div className={ "articles" } >
              <ArticlesPage userID={ this.state.userID } page={ "articles" } title={ "Colorado Counties Inc. Suspected of Tax Fraud" } />
            </div>
          )}/>

{/* Title
//Dexcription
//Author
//Date
//Category
//rootCategory
//subCategory */}

          <Route exact={true} path='/Articles/EsperantoSLV/' render={ () => (
            <div className={ "articles" } >
              <EsperantoSLVArticlesPage userID={ this.state.userID } page={ "art-esperanto" } title={ "Main" } />
            </div>
          )}/>

          <Route exact={true} path='/Articles/EsperantoSLV/Esperantoland' render={ () => (
            <div className={ "articles" } >
              <EsperantoSLVArticlesPage userID={ this.state.userID } page={ "art-esperanto" } title={ "Esperantoland" } />
            </div>
          )}/>


          <Route exact={true} path='/Articles/Alamosa-County/' render={ () => (
            <div className={ "art-alamosa" } >
              <AlamosaArticlesPage userID={ this.state.userID } page={ "art-alamosa" } title={ "Main" } />
            </div>
          )}/>

          <Route exact={true} path='/Articles/Alamosa-County/Alamosa-Republicans-Hate-Poor-People' render={ () => (
            <div className={ "art-alamosa" } >
              <AlamosaArticlesPage userID={ this.state.userID } page={ "art-alamosa" } title={ "Alamosa Republicans Hate Poor People" } />
            </div>
          )}/>

          <Route exact={true} path='/Articles/Alamosa-County/Alamosa-County-Denies-Property-Access-To-Disabled-Veteran' render={ () => (
            <div className={ "art-alamosa" } >
              <AlamosaArticlesPage userID={ this.state.userID } page={ "art-alamosa" } title={ "Alamosa County Denies Property Access To Disabled Veteran" } />
            </div>
          )}/>

          <Route exact={true} path='/Articles/Alamosa-County/Alamosa-County-Protects-Unethical-Attorney' render={ () => (
            <div className={ "art-alamosa" } >
              <AlamosaArticlesPage userID={ this.state.userID } page={ "art-alamosa" } title={ "Alamosa County Protects Unethical Attorney" } />
            </div>
          )}/>



        </div>

      </BrowserRouter>
    );
  } // end render( )
} // end class App



export default App;
