
import React, { Component } from 'react';

import '../App.css';



var loggedOn = false;




class TermsOfServiceCPane extends Component {

  constructor( props ) {

    super( props );

    this.state = {

      value: '',
      userID: '',
      page: '',
      title: ''

    };


    this.state.userID = props.userID;
    this.state.page = props.page;
    this.state.title = props.title;

}


  handleChange( ) {

  }


  render() {

    if( loggedOn )
    {

      return (
        <div>
        {/* this.renderSiteFooter( ) */}
        </div>
      );
    }
    else
    {

      return (
         <div>
           <b>ECCEP.co Terms of Service</b>

           <p><b>Disambiguation/definition of key words and phrases</b></p>


           <p><b>User rights and responsibilities</b></p>


           <p><b>Proper or Expected usafe; definition of misuse</b></p>

           <p><b>Accountability for online actions, behavior, and conduct</b></p>

           <p><b>Privacy policy outlining the use of personal data</b></p>

           <p><b>Payment details such as membership or subscription fees, etc.</b></p>

           <p><b>Opt-out policy describing procedure for account termination, if available</b></p>

           <p><b>Arbitration detailing the dispute resolution process and limited rights to take a claim to court</b></p>

           <p><b>Disclaimer/Limitation of Liability clarifying the site's legal liability for damages incurred by users</b></p>

           <p><b>User notification upon modification of terms, if offered</b></p>


         </div>
      );
    }
  }
}


export default TermsOfServiceCPane;
